export interface LevelCondition {
   win: boolean,
   type: LEVEL_CONDITION_TYPE,
   number: Number
}

export class LEVEL_CONDITION_TYPE {
  static rightOnes = 0;
  static wrongOnes = 1;
  static rightOnesInARow = 2;
  static wrongOnesInARow = 3;
  static duration = 4;
  static timeUntilNextMove = 5;
}
