<div class="flexbox">
   <button class="ui big icon button" (click) = "(menu.getValue() == false) ? menu.next(true) : true" >
      <i class="bars icon"></i>
   </button>

   <img class="logo" src="assets/Logo.png">
</div>



<br>
<div class="flexbox stretch">
   <button class="ui icon button startGame">
      <i *ngIf="!showPause" class="play icon"></i>
      <i *ngIf="showPause" class="pause icon"></i>
   </button>
   <!--[disabled]="stopDisabled"-->
   <button class="ui icon button stopGame"><i class="stop icon"></i></button>
</div>
<br>
<div *ngIf="showPause">
<button *ngIf="tippsActive" class="showTipp ui button tipp">Tipp</button>
<br>
<br>
<div class="flexbox stretch">
   <button class="ui icon button zoomIn"><i class="plus icon "></i></button>
   <button class="ui icon button zoomOut"><i class="minus icon "></i></button>
</div>
<br>

<!--<button class="zoomIn">+</button>
<button class="zoomOut">-</button><br>-->

<app-statistic [liveCount]="true"></app-statistic>
</div>
