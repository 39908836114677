<div class="fingerMatrix">
  <div *ngIf="minMatrix" class="flexbox">
    <div class="trDiv iconActive" *ngFor="let row of matrix; index as i">
      <i *ngFor="let element of row;index as j" class="white icon fingerBoardButton" [ngClass]="{
               'circle': element.circle,
               'square': !element.circle,
               'big': i == 0,
               'outline': !element.playable,
               'link': element.playable,
               'color0': element.color == COLOR.color0,
               'color1': element.color == COLOR.color1,
               'color2': element.color == COLOR.color2,
               'color3': element.color == COLOR.color3,
               'color4': element.color == COLOR.helpLines,
               'tipp': element.tipp,
               'multipleChoise': element.multipleChoise,
               'multipleChoiseDimm': multipleChoiseActive && !element.multipleChoise
            }" cdkDropList
            [id]="'finger'+i+'_'+j"
            #fingerBoard="cdkDropList"
            (click)="click(i, j)"
            [cdkDropListConnectedTo]="[dragFingers]"
            (cdkDropListDropped)="drop($event, i, j)"></i>
    </div>
  </div>
  <div *ngIf="!minMatrix" class="flexbox">
    <div class="trDiv labelActive" *ngFor="let row of matrix; index as i">
      <label *ngFor="let element of row;index as j" class="fingerBoardButton" [ngClass]="{
            'circle': element.circle,
            'square': !element.circle,
            'big': i == 0,
            'outline': !element.playable,
            'link': element.playable,
            'color0': element.color == COLOR.color0,
            'color1': element.color == COLOR.color1,
            'color2': element.color == COLOR.color2,
            'color3': element.color == COLOR.color3,
            'color4': element.color == COLOR.helpLines,
            'tipp': element.tipp,
            'multipleChoise': element.multipleChoise,
            'multipleChoiseDimm': multipleChoiseActive && !element.multipleChoise
         }"
         cdkDropList
         [id]="'finger'+i+'_'+j"
         (click)="click(i, j)"
         #fingerBoard="cdkDropList"
         [cdkDropListConnectedTo]="[dragFingers]"
         (cdkDropListDropped)="drop($event, i, j)">
         <label *ngIf="element.showName">{{element.showName}}</label></label>
    </div>
  </div>
</div>
