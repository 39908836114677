import { Injectable } from '@angular/core';
import { AsyncValidator, FormControl, ValidationErrors } from "@angular/forms";

import { AuthService } from "../../../shared/auth.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators"


@Injectable({
  providedIn: 'root'
})
export class UsernameValidatorService implements AsyncValidator {

   exeptValue = [];

  constructor(private auth: AuthService) { }

  exept(value) {
     this.exeptValue.push(value);
 }

  validate(control: FormControl): Observable<ValidationErrors | null> {

     if(control.value<3) {
        return of(null);
     }

     if(this.exeptValue.includes(control.value))
       return of(null);

    return this.auth.usernameExists(control.value).pipe(
      map(exists =>  (exists === false) ? null : { exists: {valid: false}})
   );
  }
}
