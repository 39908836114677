<button class="ui big icon button" (click)="menuService.next(true)">
  <i class="bars icon"></i>
</button>

<div class="ui vertical menu root">
  <a class="blue item" [ngClass]="{active: menu == 0}" (click)="(menu != 0) ? menu = 0 : menu = -1">
    <h3>Persönliche Angaben</h3>
  </a>
</div>
<div *ngIf="menu == 0" class="active item">
  <form class="ui large form" [formGroup]="myData" (ngSubmit)="saveMyData()">
    <div class="ui stacked segment">

      <div class="field" [formGroup]="myData.get('name')">
        <label class="ui aligned left header">Name (optional)</label>
        <div class="ui two column grid">

          <div class="column">
            <input type="text" formControlName="firstName" placeholder="Vorname">
          </div>
          <div class="column">
            <input type="text" formControlName="lastName" placeholder="Nachname">
          </div>
        </div>
      </div>

      <!--[disabled]="signIn.invalid"-->
      <button class="ui fluid large blue submit button" type="submit">Name ändern</button>
    </div>



    <div class="ui error message active">ERROR</div>

  </form>
</div>

<div class="ui vertical menu root">
  <a class="blue item" [ngClass]="{active: menu == 1}" (click)="(menu != 1) ? menu = 1 : menu = -1">
    <h3>Benutzername</h3>
  </a>
</div>
<div *ngIf="menu == 1" class="active item">
  <form class="ui large form" [formGroup]="accessData" (ngSubmit)="saveAccessData()">
    <div class="ui stacked segment">

      <div class="field">
        <label class="ui aligned left header">Benutzername (optional)</label>
        <div class="ui right icon input">
          <i class="user icon"></i>
          <input type="text" formControlName="username" placeholder="Benutzername">
        </div>
        <app-form-messages [control]="accessData.get('username')" controlName="username"></app-form-messages>
      </div>

      <div class="field">
        <label for="eMail" class="ui aligned left header">E-Mail</label>
        <div class="ui right icon input">
          <i class="user icon"></i>
          <input type="email" formControlName="eMail" placeholder="E-Mail Adresse">
        </div>
        <app-form-messages [control]="accessData.get('eMail')" controlName="eMail"></app-form-messages>
      </div>


      <!--[disabled]="signIn.invalid"-->
      <button class="ui fluid large blue submit button" type="submit" [disabled]="accessData.invalid">Zugangsdaten ändern</button>
    </div>



    <div class="ui error message active">ERROR</div>

  </form>
</div>
<div class="ui vertical menu root">
  <a class="blue item" [ngClass]="{active: menu == 2}" (click)="(menu != 2) ? menu = 2 : menu = -1">
    <h3>Passwort</h3>
  </a>
</div>
<div *ngIf="menu == 2" class="active item">
  <form class="ui large form" [formGroup]="pwData" (ngSubmit)="savePwData()">
    <div class="ui stacked segment">

      <div class="field">
        <label class="ui aligned left header">Altes Passwort</label>
        <div class="ui right icon input">
          <i class="lock icon"></i>
          <input type="password" formControlName="oldPassword" placeholder="Password">
        </div>
      </div>

      <div class="field">
        <label class="ui aligned left header">Neues Passwort</label>
        <div class="ui right icon input">
          <i class="lock icon"></i>
          <input type="password" formControlName="password" placeholder="Password">
        </div>
        <app-form-messages [control]="pwData.get('password')" controlName="password"></app-form-messages>
      </div>

      <!--[disabled]="signIn.invalid"-->
      <button class="ui fluid large blue submit button" type="submit">Passwort ändern</button>
    </div>



    <div class="ui error message active">ERROR</div>

  </form>
</div>

<app-dimmed-message [info]="infoMsg" [show]="showMsg" time="3000" (finished) = "clearTimeout()"></app-dimmed-message>
