import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLevelTableComponent } from './admin-area/admin-level-table/admin-level-table.component';
import { FormConfigureComponent } from './admin-area/form-configure/form-configure.component';
import { NgEventBus } from 'ng-event-bus';
import { AdminConfigureComponent } from './admin-area/admin-configure/admin-configure.component';
import { LoginComponent } from "./login/login.component";
import { FormGameRulesComponent } from './admin-area/form-game-rules/form-game-rules.component';
import { GameComponent } from './game/game.component';
import { StopScreenComponent } from './game/screen/stop-screen/stop-screen.component';
import { StartScreenComponent } from './game/screen/start-screen/start-screen.component';
import { StatisticComponent } from './game/statistic/statistic.component';
import { FormDividerComponent } from './admin-area/form-divider/form-divider.component';
import { AdminDivideComponent } from './admin-area/admin-divide/admin-divide.component';
import { DivideTableComponent } from './admin-area/divide-table/divide-table.component';
import { AdminMenuComponent } from './admin-area/admin-menu/admin-menu.component';
import { WinStatisticComponent } from './game/win-statistic/win-statistic.component';
import { BreakScreenComponent } from './game/screen/break-screen/break-screen.component';
import { ScreenHeaderComponent } from './game/screen/screen-header/screen-header.component';
import { LeftMenuComponent } from './game/left-menu/left-menu.component';
import { ScreenComponent } from './game/screen/screen/screen.component';
import { FingerBoardComponent } from './game/finger-board/finger-board.component';
import { HelpLinesComponent } from './game/help-lines/help-lines.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { DragFingersComponent } from './game/drag-fingers/drag-fingers.component';
import { SignInComponent } from "./login/sign-in/sign-in.component";
import { LostPwComponent } from "./login/lost-pw/lost-pw.component";
import { NewPwComponent } from "./login/new-pw/new-pw.component";
import { FormMessagesComponent } from "./login/form-messages/form-messages.component";
import { DimmedMessageComponent } from './dimmed-message/dimmed-message.component';
import { LevelProgressionComponent } from './level-progression/level-progression.component';
import { MenuComponent } from './menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SettingsComponent } from './settings/settings.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminLevelTableComponent,
    FormConfigureComponent,
    AdminConfigureComponent,
    LoginComponent,
    FormGameRulesComponent,
    GameComponent,
    StopScreenComponent,
    StartScreenComponent,
    StatisticComponent,
    FormDividerComponent,
    AdminDivideComponent,
    DivideTableComponent,
    AdminMenuComponent,
    WinStatisticComponent,
    BreakScreenComponent,
    ScreenHeaderComponent,
    LeftMenuComponent,
    ScreenComponent,
    FingerBoardComponent,
    HelpLinesComponent,
    DragFingersComponent,
    FormMessagesComponent,
    SignInComponent,
    NewPwComponent,
    LostPwComponent,
    DimmedMessageComponent,
    LevelProgressionComponent,
    MenuComponent,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    DragDropModule,
    BrowserAnimationsModule
  ],
  providers: [
    NgEventBus
 ],
  bootstrap: [AppComponent]
})
export class AppModule { }
