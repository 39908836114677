<h1><label *ngIf="win">Gewonnen</label><label *ngIf="!win">Verloren</label></h1>
<div class="flexbox row">


  <div *ngIf="bronzeM" class="flexbox column">
    <i class="icon-medaille bronze"></i>
    BRONZE
  </div>
  <div *ngIf="silverM" class="flexbox column">
    <i class="icon-medaille silver"></i>
    SILBER
  </div>
  <div *ngIf="goldM" class="flexbox column">
    <i class="icon-medaille gold"></i>
    GOLD
  </div>
</div>
