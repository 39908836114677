import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { AuthService } from "../shared/auth.service";
import { Router} from "@angular/router";
import { LevelsService } from "../shared/levels.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  login: FormGroup;
  loading = true;
  showIncorrect = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private levels: LevelsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.loading = false;
  }

  private initForm() {

    this.login = this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  tryLogin() {

    this.loading = true;
    const { username, password } = this.login.value;

    this.auth.login(username, password).subscribe(result => {
      this.loading = false;
      if (result.login == true) {
         console.log("NAVIGATE TO GAME/highestUNLOCKEDLEVEL");
         this.levels.getLowestUnlockedLevel().subscribe(level => {this.router.navigateByUrl("game/"+level);});
     } else {

        this.showIncorrect = true;
     }
    });
  }

}
