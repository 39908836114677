<div class="ui middle aligned center aligned grid">
  <div class="column">
    <h2 class="ui blue image header">
      <img src="assets/Logo.png" class="image" alt="LOGO">
    </h2>
    <form class="ui large form" (ngSubmit)="tryLogin()" [formGroup]="login">
      <div class="ui stacked segment">
        <div class="field">
   <label for="username">Benutzername</label>
          <div class="ui left icon input">
            <i class="user icon"></i>
            <input type="text" formControlName="username" placeholder="Benutzername | E-mail Addresse">
          </div>
        </div>
        <div class="field">
           <label for="password">Passwort</label>
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input type="password" formControlName="password" placeholder="Passwort">
          </div>
        </div>
        <button type="submit" [disabled]="login.invalid"  class="ui fluid large blue submit button">Login</button>
      </div>
    </form>
    <div class="ui negative message" *ngIf="showIncorrect">
       Benutzername oder Passwort nicht korrekt.
    </div>
    <div class="ui message">
      <a routerLink="/lost-pw">Passwort Vergessen?</a>
   </div>
    <div class="ui message">
      Neu bei uns? <a routerLink="/sign-in">Hier Registrieren</a>
    </div>
  </div>
</div>
<div class="ui active dimmer" *ngIf="loading">
      <div class="ui large text loader">Daten werden geladen ...</div>
   </div>
