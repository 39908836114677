import { Component, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Statistic } from "../shared/statistic";
import { GameService } from "../service/game.service";
import { Observe } from "../shared/observe";

@Component({
  selector: 'app-win-statistic',
  templateUrl: './win-statistic.component.html',
  styleUrls: ['./win-statistic.component.scss']
})
export class WinStatisticComponent implements OnInit {
  win = false;
  bronzeM = false;
  silverM = false;
  goldM = false;


  constructor(
    private game: GameService, private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.game.stats.subscribe(this.observe, this);

  }

  refresh(stat) {
    if (stat == undefined || stat.win != true) {
      this.win = false;
      this.goldM = false;
      this.silverM = false;
      this.bronzeM = false;
      return false;
    }

    this.win = true
    this.goldM = false;
    this.silverM = false;
    this.bronzeM = false;
    if (stat.gold) {
      this.goldM = true;
    } else if (stat.silver) {
      this.silverM = true;
    } else if (stat.bronze) {
      this.bronzeM = true;
    }
    this.cdr.detectChanges();
  }

  observe(arg, action) {
    if (action == "STATISTIC_NEW") {
      this.refresh(arg);
    }
  }

}
