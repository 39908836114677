import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "../../shared/user";
import { AuthService } from "../../shared/auth.service";
import { filter, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators'
import { EmailValidatorService } from "../shared/Validators/email-validator.service";
import { UsernameValidatorService } from "../shared/Validators/username-validator.service";


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {


  signIn: FormGroup;
  loading = false;

  createdUserMsg = false;
  errorMsg = false;
  infoStr = "Ihr Benutzerzugang wurde erfolgreich erstellt";

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private emailValidator: EmailValidatorService,
    private usernameValidator: UsernameValidatorService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.signIn = this.fb.group({
      name: this.fb.group({
        firstName: "",
        lastName: "",
      }),
      username: ["", [Validators.minLength(3)], [this.usernameValidator]],
      eMail: ["", [Validators.required, Validators.email], [this.emailValidator]],
      password: ["", [Validators.required, Validators.minLength(6)]]
    });
  }

  routeLogin() {
     this.router.navigateByUrl("/login");
 }

  createAccount() {
     if(this.signIn.valid != true)
      return false;

    this.loading = true;
    const { name, eMail, username, password } = this.signIn.value;
    const user: User = {name, eMail, username, password};


    this.auth.createUser(user).subscribe(result => {
      this.loading = false;
      if (result.createUser === true) {
         this.createdUserMsg = true;
     } else {
        this.infoStr = "Es ist ein Fehler aufgetreten.";
        alert("Es ist ein Fehler aufgetreten");
        if(result.usernameExists === true) {
           // gib es dementsprechend aus
        }
        if(result.eMailExists === true) {
           // gib es dementsprechend aus
        }
     }
    });
  }

}
