<table class="ui celled structured table main" [ngClass]= "{'mini': !expanded, 'hideStats': hideStats, 'hideDesc': hideDesc}">
  <thead>
    <tr>
      <th>
         <i *ngIf="expanded" class="compress icon" (click)="expanded = false"></i>
         <i *ngIf="expanded == false" class="expand icon" (click)="expanded = true"></i>
      </th>
      <th>Level</th>
      <th>
         <i *ngIf="!hideDesc" class="compress icon" (click)="hideDesc = true"></i>
         <i *ngIf="hideDesc" class="expand icon" (click)="hideDesc = false"></i>
         <label *ngIf="!hideDesc">Beschreibung</label>
      </th>
      <th >Noten im Spiel</th>
      <th >Geige - Griffbrett</th>
      <th >Notenzeile</th>
      <th >Spieltyp</th>
      <th >Spiel Endbedingung</th>
      <th>
         <i *ngIf="!hideStats" class="compress icon" (click)="hideStats = true"></i>
         <i *ngIf="hideStats" class="expand icon" (click)="hideStats = false"></i>
         <label *ngIf="!hideStats">Statistiken</label>
      </th>
    </tr>

  </thead>
  <tbody>


    <tr *ngFor="let level of levels; index as i" (dblclick)="loadInEdit(level)" [id]="'level'+level.ID">
      <td>
        <a [href]='"game/"+level.ID'><button class="ui button"><i class="play icon"></i>Spielen</button></a><nobr>
        <i (click)="editLevel(i, level.ID)" class="large edit icon"></i>
        <i (click)="deleteLevel(i, level.ID)" class="large delete icon"></i>
        <i (click)="moveLevelUp(i, level.ID)" *ngIf="i>0" class="large angle up icon"></i>
        <i (click)="moveLevelDown(i, level.ID)" *ngIf="i<levels.length-1" class="large angle down icon"></i>
   </nobr>
      </td>
      <td class="center aligned">
         {{level.ID}}
      </td>
      <td>
         <div>
            {{level.description}}
         </div>
      </td>
      <td class="top aligned" >
        <table class="ui definition table">
          <tbody>
             <tr>
                <td>Leere Seiten</td>
                <td [ngClass]="{'markDifferent': markDifferent(i, 'basicTone')}">
                   <i *ngIf="level.basicTone" class="large  check icon"></i>
                   <i *ngIf="!level.basicTone" class="large times icon"></i>
                </td>
                </tr>
            <tr>
              <td>Lagen</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'lagen')}">{{printLagen(level.lagen)}}</td>
            </tr>
            <tr>
              <td>Gruppe</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'group')}">
                 <label *ngIf="level.group ==-1">Alle Töne</label>
              <label *ngIf="level.group ==0">Gruppe 1</label>
              <label *ngIf="level.group ==1">Gruppe 2</label>
              <label *ngIf="level.group ==2">Gruppe 3</label>
           </td>
            </tr>
            <tr>
              <td>Tonart</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'tonality')}">
                 <label *ngIf="level.tonality =='whole'">Alle Töne</label>
                 <label *ngIf="level.tonality =='cDur'">C-Dur</label>
                 <label *ngIf="level.tonality =='bDur'">B-Dur</label>
                 <label *ngIf="level.tonality =='dDur'">D-Dur</label>
                 <label *ngIf="level.tonality =='fDur'">F-Dur</label>
                 <label *ngIf="level.tonality =='gDur'">G-Dur</label>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td >
        <table class="ui definition table">
          <tbody>
            <tr>
              <td>Rotation</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'rotation')}">{{level.rotation}}</td>
            </tr>
            <tr>
              <td>zeige Finger</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'showFinger')}">
                 <i *ngIf="level.showFinger" class="large  check icon"></i>
                 <i *ngIf="!level.showFinger" class="large times icon"></i>
              </td>
            </tr>
            <tr>
              <td>zeige Hilfslinien</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'showHelpLines')}">
                 <i *ngIf="level.showHelpLines" class="large  check icon"></i>
                 <i *ngIf="!level.showHelpLines" class="large times icon"></i>
              </td>
            </tr>
            <tr>
              <td>zeige C-Dur</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'showCssDur')}">
                 <i *ngIf="level.showCssDur" class="large  check icon"></i>
                 <i *ngIf="!level.showCssDur" class="large times icon"></i>
              </td>
            </tr>
            <tr>
              <td>Farb. Markierung</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'colorMarkupPoints')}">
                 <label *ngIf="level.colorMarkupPoints ==0">keine</label>
                 <label *ngIf="level.colorMarkupPoints ==1">Hilfslinien</label>
                 <label *ngIf="level.colorMarkupPoints ==2">Finger in cDur</label>
                 <label *ngIf="level.colorMarkupPoints ==3">Finger in bDur</label>
                 <label *ngIf="level.colorMarkupPoints ==4">Finger in dDur</label>
                 <label *ngIf="level.colorMarkupPoints ==5">Finger in fDur</label>
                 <label *ngIf="level.colorMarkupPoints ==6">Finger in gDur</label>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Anzahl Vorschläge (Multiple Choise)</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'numNotesFingerBoard')}">{{level.numNotesFingerBoard}}</td>
            </tr>
            <tr>
              <td>Lage Automatisch</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'playingLageAuto')}">
                 <i *ngIf="level.playingLageAuto" class="large  check icon"></i>
                 <i *ngIf="!level.playingLageAuto" class="large times icon"></i>
              </td>
            </tr>
            <tr>
              <td>Auswahl Modus</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'fingerMode')}">
                 <label *ngIf="level.fingerMode ==0">Klicken</label>
                 <label *ngIf="level.fingerMode ==1">Finger ziehen</label>
                 <label *ngIf="level.fingerMode ==2">Finger tippen</label>
                 <label *ngIf="level.fingerMode ==3">Finger greifen (mobil)</label>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td >
        <table class="ui definition table">

          <tbody>
            <tr>
              <td>Anzahl Noten</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'numNotesNoteBoard')}">{{level.numNotesNoteBoard}}</td>
            </tr>
            <tr>
              <td>Schreib/Notenschrift</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'outputMode')}">
                 <label *ngIf="level.outputMode ==3">Gemischt</label>
                 <label *ngIf="level.outputMode ==2">Schreibschrift</label>
                 <label *ngIf="level.outputMode ==1">Notenschrift</label>
                 <label *ngIf="level.outputMode ==0">Zeige Beide</label>

              </td>
            </tr>
            <tr>
              <td>Fingerbeschriftung</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'showFingerNum')}">
                 <label *ngIf="level.showFingerNum ==0">keine Fingerbeschriftung</label>
                 <label *ngIf="level.showFingerNum ==3">Fingerbeschriftung bei Lagewechsel</label>
                 <label *ngIf="level.showFingerNum ==1">immer eine Fingerbeschriftung</label>
                 <label *ngIf="level.showFingerNum ==2">zufällige Fingerbeschriftung</label>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td >
        <table class="ui definition table">

          <tbody>
            <tr>
              <td>Notennamen aktiv</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'notesTurnable')}">
                 <i *ngIf="level.notesTurnable" class="large  check icon"></i>
                 <i *ngIf="!level.notesTurnable" class="large times icon"></i>
              </td>
            </tr>
            <tr>
              <td>Lade mit Notennamen</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'defaultShowNames')}">
                 <i *ngIf="level.defaultShowNames" class="large  check icon"></i>
                 <i *ngIf="!level.defaultShowNames" class="large times icon"></i>
              </td>
            </tr>
            <tr>
              <td>Tipps Aktiv</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'tippsActive')}">
                 <i *ngIf="level.tippsActive" class="large  check icon"></i>
                 <i *ngIf="!level.tippsActive" class="large times icon"></i>
              </td>
            </tr>
            <tr>
              <td>Tipp nach ... falschen</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'showTippAfterNumWrongOnes')}">{{level.showTippAfterNumWrongOnes}}</td>
            </tr>
            <tr>
              <td>Spielart</td>
              <td [ngClass]="{'markDifferent': markDifferent(i, 'gameMode')}">
                 <label *ngIf="level.gameMode ==0">Finde die Note</label>
              <label *ngIf="level.gameMode ==1">Multiple Choise - Finde auf der Geige</label>
              <label *ngIf="level.gameMode ==2">Multiple Choise - Finde auf der Notenzeile</label>
              <label *ngIf="level.gameMode ==3">Stück Mitspielen</label>
           </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td>
         <h4>Level Aufstieg</h4>
        <table class="ui definition table" *ngIf="level.levelCondition != undefined">
          <tbody>
             <tr *ngFor="let rule of level.levelCondition; index as j" [ngClass]="{'markDifferent': markDifferent(i, 'levelCondition', j)}">
                <td *ngIf="rule.win == true || rule.win == 'true'">Gewinnt</td>
                <td *ngIf="rule.win == false || rule.win == 'false'">Verliert</td>
                <td>
                     {{rule.number}}
                     <label *ngIf="rule.type == 0">Richtige</label>
                     <label *ngIf="rule.type == 1">Falsche</label>
                     <label *ngIf="rule.type == 2">Richtige hintereinander</label>
                     <label *ngIf="rule.type == 3">Falsche hintereinander</label>
                     <label *ngIf="rule.type == 4">sec Spielzeit</label>
                     <label *ngIf="rule.type == 5">sec Zeit bis zum nächsten Spielzug</label>
                </td>

            </tr>
          </tbody>
          </table>

          <h4 *ngIf="level.bronzeCondition != undefined && level.bronzeCondition.length > 0">Bronze</h4>
          <table class="ui definition table" *ngIf="level.bronzeCondition != undefined">
            <tbody>
               <tr *ngFor="let rule of level.bronzeCondition; index as j" [ngClass]="{'markDifferent': markDifferent(i, 'bronzeCondition', j)}">
                  <td *ngIf="rule.win == true || rule.win == 'true'">Gewinnt</td>
                  <td *ngIf="rule.win == false || rule.win == 'false'">Verliert</td>
                  <td>
                       {{rule.number}}
                       <label *ngIf="rule.type == 0">Richtige</label>
                       <label *ngIf="rule.type == 1">Falsche</label>
                       <label *ngIf="rule.type == 2">Richtige hintereinander</label>
                       <label *ngIf="rule.type == 3">Falsche hintereinander</label>
                       <label *ngIf="rule.type == 4">sec Spielzeit</label>
                       <label *ngIf="rule.type == 5">sec Zeit bis zum nächsten Spielzug</label>
                  </td>

              </tr>
            </tbody>
        </table>
        <h4 *ngIf="level.silverCondition != undefined && level.silverCondition.length > 0">Silver</h4>
        <table class="ui definition table" *ngIf="level.silverCondition != undefined">
          <tbody>
             <tr *ngFor="let rule of level.silverCondition; index as j" [ngClass]="{'markDifferent': markDifferent(i, 'silverCondition', j)}">
                <td *ngIf="rule.win == true || rule.win == 'true'">Gewinnt</td>
                <td *ngIf="rule.win == false || rule.win == 'false'">Verliert</td>
                <td>
                     {{rule.number}}
                     <label *ngIf="rule.type == 0">Richtige</label>
                     <label *ngIf="rule.type == 1">Falsche</label>
                     <label *ngIf="rule.type == 2">Richtige hintereinander</label>
                     <label *ngIf="rule.type == 3">Falsche hintereinander</label>
                     <label *ngIf="rule.type == 4">sec Spielzeit</label>
                     <label *ngIf="rule.type == 5">sec Zeit bis zum nächsten Spielzug</label>
                </td>

            </tr>
          </tbody>
     </table>
     <h4 *ngIf="level.goldCondition != undefined && level.goldCondition.length > 0">Gold</h4>
     <table class="ui definition table" *ngIf="level.goldCondition != undefined">
      <tbody>
          <tr *ngFor="let rule of level.goldCondition; index as j" [ngClass]="{'markDifferent': markDifferent(i, 'goldCondition', j)}">
             <td *ngIf="rule.win == true || rule.win == 'true'">Gewinnt</td>
             <td *ngIf="rule.win == false || rule.win == 'false'">Verliert</td>
             <td>
                  {{rule.number}}
                  <label *ngIf="rule.type == 0">Richtige</label>
                  <label *ngIf="rule.type == 1">Falsche</label>
                  <label *ngIf="rule.type == 2">Richtige hintereinander</label>
                  <label *ngIf="rule.type == 3">Falsche hintereinander</label>
                  <label *ngIf="rule.type == 4">sec Spielzeit</label>
                  <label *ngIf="rule.type == 5">sec Zeit bis zum nächsten Spielzug</label>
             </td>

         </tr>
      </tbody>
  </table>
      </td>
      <td>
         <table class="ui definition table">
           <tbody>
             <tr>
                <td>
                   Gespielt
                </td>
                <td>
                   {{level.stat.numPlayed}} mal
                </td>
             </tr>
             <tr>
                <td>Gewonnene Spiele</td>
                <td>{{level.stat.won}}</td>
             </tr>
             <tr>
                <td>Durchschnittliche Zeit</td>
                <td>{{level.stat.avrTime}}</td>
             </tr>
             <tr>
                <td>Durchschnittliche Zeit für Gewonnene Spiele</td>
                <td>{{level.stat.avrTimeWon}}</td>
             </tr>
             <tr>
                <td>Durchschnittliche Zeit für Verlorene Spiele</td>
                <td>{{level.stat.avrTimeLost}}</td>
             </tr>
           </tbody>
         </table>
      </td>
    </tr>

  </tbody>
</table>

<br><br>
