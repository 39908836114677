import { Injectable } from '@angular/core';
import { FormControl, AsyncValidator, ValidationErrors} from "@angular/forms";
import { Observable, of } from "rxjs";
import { map, catchError} from "rxjs/operators";

import { AuthService } from "../../../shared/auth.service";

@Injectable({
  providedIn: 'root'
})
export class EmailValidatorService implements AsyncValidator{

   exeptValue = [];

  constructor(
     private bs: AuthService
 ) { }

 exept(value) {
    this.exeptValue.push(value);
}

 validate(control: FormControl): Observable<ValidationErrors | null> {
    return of(null);
    if(this.exeptValue.includes(control.value))
      return of(null);

    return this.bs.eMailExists(control.value).pipe(
      map(exists => (exists === false) ? null : { exists: {valid: false}}),
      catchError(err => of(null))
      );
   }
}
