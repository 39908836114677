import { Component, OnInit, Renderer2 } from '@angular/core';
import { Observable } from "rxjs";

import { Level } from "../../shared/settings";
import { LevelsService } from "../../shared/levels.service";

import { NgEventBus } from 'ng-event-bus';
import { MetaData } from "ng-event-bus/lib/meta-data";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-admin-level-table',
  templateUrl: './admin-level-table.component.html',
  styleUrls: ['./admin-level-table.component.scss']
})
export class AdminLevelTableComponent implements OnInit {


  levels: Level[];
  //tableSettings = [];
  expanded = true;
  hideStats = true;
  hideDesc = true;


  constructor(
    private ls: LevelsService,
    private eventBus: NgEventBus,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.eventBus.on("refresh").subscribe((meta: MetaData) => {
      this.refreshLevels();
    });

    //this.ls.getAllLevels().subscribe(data => {
    this.refreshLevels();
    //});


    //$("#level"+levelID)[0].scrollIntoView();


  }

  markDifferent(index, name, index2 = null) {
    if (index > 0) {
      if (index2 != null) {
        if (this.levels[index - 1][name] == undefined)
          return true;
        if (JSON.stringify(this.levels[index][name][index2]) != JSON.stringify(this.levels[index - 1][name][index2]))
          return true;
        else {
          return false;
        }
      }

      if (JSON.stringify(this.levels[index][name]) != JSON.stringify(this.levels[index - 1][name]))
        return true;
    }
    return false;
  }



  private moveLevel(index, ID, num) {
    ID = ID - 0;
    if (this.levels[index + num].ID == ID + num) { // swap
      console.log("SWAP");
      this.ls.swapLevel(this.levels[index + num], this.levels[index]).subscribe(success => {
        if (success) {
          this.refreshLevels();
        } else {
          alert("Es ist ein Fehler aufgetreten");
        }
      });
    } else { // Only Update
      const level = { ...this.levels[index], ID: ID + num };
      this.ls.updateLevel(ID, level).subscribe(success => {
        if (success) {
          this.refreshLevels();
        } else {
          alert("Es ist ein Fehler aufgetreten");
        }
      });
    }
  }

  moveLevelUp(index, ID) {
    this.moveLevel(index, ID, -1);
  }

  moveLevelDown(index, ID) {
    this.moveLevel(index, ID, 1);
  }

  deleteLevel(index, ID) {
    if (confirm("Wollen Sie das Level " + ID + " wirklich löschen?")) {
      this.ls.deleteLevel(ID).subscribe(success => {
        if (success) {
          this.levels.splice(index, 1);
          alert("Level erfolgreich gelöscht");
          this.refreshLevels();
        } else {
          alert("Es ist ein Fehler aufgetreten");
        }
      });
    }
  }

  toggleExpand() {
    console.log("toggleExpanded");
    this.expanded = !this.expanded;
  }

  editLevel(index, ID) {
    const level = this.levels[index];
    console.log("editLevel", level);
    this.eventBus.cast("editLevel", level);
  }

  loadInEdit(level) {
    console.log("dblClick", level);
    this.eventBus.cast("dblClick", level);
  }



  private refreshLevels() {
    this.ls.getAllLevels().subscribe(data => {
      this.levels = data.getAllLevels;


      const levelID = this.route.snapshot.paramMap.get("levelID");

      if (levelID != undefined) {
      }

      //this.initTableSettings();
    });
  }

  printLagen(lage) {
    let tmp: any[];
    tmp = [...lage];
    for (let j = 0; j < tmp.length; j++) {
      tmp[j] = tmp[j] + 1;
    }
    return tmp;
  }
  /*
    private initTableSettings() {
       this.tableSettings = [];
       for(let i=0;i<this.levels.length;i++) {
        this.tableSettings.push({
            ID: this.levels[i].ID,
            expanded: true
        })
      }
  }
  */
}
