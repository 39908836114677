import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";

import { Level } from "../shared/settings";
import { LevelsService } from "../shared/levels.service";
import { NgEventBus } from 'ng-event-bus';
import { MetaData } from "ng-event-bus/lib/meta-data";
import { LevelToText } from "../shared/level-to-text";
import { ActivatedRoute } from "@angular/router";
import { SettingsService } from "../shared/settings.service";
import { MenuService } from "../shared/menu.service";
import { Router } from "@angular/router";
import { Medal } from "../shared/medal";

@Component({
  selector: 'app-level-progression',
  templateUrl: './level-progression.component.html',
  styleUrls: ['./level-progression.component.scss']
})
export class LevelProgressionComponent implements OnInit {

  Medal = Medal;
  levels: any[] = [];
  //tableSettings = [];
  expanded = false;
  hideStat = true;
  hideDesc = false;
  levelID = 0;


  constructor(
    private ls: LevelsService,
    private settings: SettingsService,
    private menu: MenuService,
    private router: Router
  ) {
  }

  closeMenu(ID) {
    this.menu.next(false);
    this.router.navigateByUrl("game/" + ID);
  }

  ngOnInit(): void {

    this.settings.medal.subscribe((medal) => {
      console.log("SettingsService::Medal", medal);
      if (this.levels.length > 0) {
        let index = this.getIndexToLevel(medal.ID);
        let indexNext = this.getIndexToLevel(medal.ID + 1);
        let indexTwo = this.getIndexToLevel(medal.ID + 2);
        medal = medal.medal;

        console.log("SettingsService::nextMedal", Medal.unlockNext(medal), Medal.unlockTwo(medal));
        console.log("SettingsService::nextMedal - index", indexNext, indexTwo);
        if (indexNext == undefined && Medal.unlockNext(medal) || Medal.unlockTwo(medal) && indexTwo == undefined) {
          this.refreshLevels();
        }

        if (indexNext != undefined && this.levels[indexNext].status == Medal.LOCKED && Medal.unlockNext(medal)) {
          this.levels[indexNext].status = Medal.UNLOCKED;
        }
        if (indexTwo != undefined && this.levels[indexTwo].status == Medal.LOCKED && Medal.unlockTwo(medal)) {
          this.levels[indexTwo].status = Medal.UNLOCKED;
        }

        this.levels[index].status = medal;
      }
    });

    this.settings.ID.subscribe(ID => {
      this.levelID = ID
    });

    //this.ls.getAllLevels().subscribe(data => {
    this.refreshLevels();
    //});

    this.maximiseToLevel();
  }

  private getNextLevelIndex(index) {
    for (let i = index; i < this.levels.length; i++) {
      if (this.levels[i].ID != undefined) {
        return i;
      }
    }
    return undefined;
  }

  private checkMinMaxCondition(index, maxLevel) {
    return index < this.levels.length && (this.levels[index].ID <= maxLevel || this.levels[index].ID == undefined);
  }

  minimize(index, maxLevel) {
    if (this.levels[index].ID == undefined) {
      this.levels[index].isHidden = true;
      index++;
    } else {
      this.levels[index - 1].isHidden = true;
    }

    // when ID < maxLevel or level.ID == undefined;

    for (; this.checkMinMaxCondition(index, maxLevel); index++) {
      if (this.levels[index].ID == undefined) {

        if (!this.checkMinMaxCondition(this.getNextLevelIndex(index), maxLevel))
          break;
      }
      this.levels[index].hide = true;
    }
  }

  private getIndexToLevel(levelID) {
    for (let i = 0; i < this.levels.length; i++) {
      if (this.levels[i].ID == levelID)
        return i;
    }
    return undefined;
  }

  maximise(index, maxLevel) {

    if (this.levels[index].ID == undefined) {
      this.levels[index].isHidden = false;
      index++;
    } else {
      this.levels[index - 1].isHidden = false;
    }


    for (; this.checkMinMaxCondition(index, maxLevel); index++) {
      this.levels[index].hide = false;
      if (this.levels[index].ID == undefined && this.levels[index].isHidden == true) {
        index = this.getIndexToLevel(this.levels[index].to);
      }
    }
  }




  maximiseToLevel() {
    let ID: any = this.levelID;
    ID = ID - 0;
    if (ID == undefined || ID == 0 || this.levels == undefined)
      return false;

    for (let i = 0; i < this.levels.length; i++) {
      if (this.levels[i].ID == undefined) {
        if (this.levels[i].from <= ID && this.levels[i].to >= ID)
          this.maximise(i, this.levels[i].to);
      }
    }
  }

  private refreshLevels() {
    console.log("level-progression::refreshLevels");
    let getIndent = function(index, levels) {
      let indentNum = 0;
      for (let i = index; i < levels.length; i++) {
        if (levels[i].ID != undefined) {
          let tmp = [...levels[i].indent];
          while (indentNum < 0) {
            tmp.pop();
            indentNum++;
          }
          return tmp;
        }
        indentNum--;
      }
      return undefined;
    }

    this.ls.getLevelProgression().subscribe(data => {
      console.log("level-progression::subscribe", data);
      let newLevels = data.levels;
      newLevels.map(level => level.ID -= 0);
      let maxLevel = data.levels[data.levels.length - 1].ID;

      data.dividers = data.dividers.filter(divider => divider.from - 0 < maxLevel);
      console.log("level-progression::getHttp", data.dividers, maxLevel, data.levels)

      for (let i = 0; i < data.dividers.length; i++) {
        let indentElements = newLevels.filter(level => level.ID <= data.dividers[i].to && level.ID >= data.dividers[i].from);

        //console.log(data.dividers[i].from, data.dividers[i].to, indentElements.length);

        indentElements.map(level => {
          if (level.indent == undefined) {
            level.indent = [1];
          } else {
            level.indent.push(1);
          }
          return level;
        });
        delete data.dividers[i].ID;
        let index = newLevels.indexOf(indentElements[0]);
        newLevels.splice(index, 0, data.dividers[i]);
      }
      let minimize = [];
      // correct indent for divisors
      for (let i = newLevels.length - 1; i >= 0; i--) {
        if (newLevels[i].ID == undefined) {
          newLevels[i].indent = getIndent(i, newLevels);
          if (!(this.levels != undefined && this.levels[i] != undefined && this.levels[i].ID == undefined && this.levels[i].isHidden != true)) {
            minimize.push({ index: i, to: newLevels[i].to })
          }
        }
      }
      this.levels = newLevels;
      for (let i = 0; i < minimize.length; i++) {
        this.minimize(minimize[i].index, minimize[i].to);
      }

      this.maximiseToLevel();
    });
  }

}
