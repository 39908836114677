import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Medal } from "../../../shared/medal";

@Component({
  selector: 'app-stop-screen',
  templateUrl: './stop-screen.component.html',
  styleUrls: ['./stop-screen.component.scss']
})
export class StopScreenComponent implements OnInit, OnChanges {

   @Input() levelID?:number = null;
   @Input() status?:number = Medal.LOCKED;

  constructor(
 ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
     console.log("screen::onChanges - stop-screen", this.status);
 }

}
