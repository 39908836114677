<div *ngIf="screenType != 'hide'">
   <div class="flexbox">
      <div class="flexbox center" *ngIf="screenType !='break'">
            <i *ngIf="levelID >1" class="massive link angle left icon" [routerLink]="['/game', levelID-1]"></i>
            <i *ngIf="levelID < 2" class="massive grey angle left icon" ></i>
      </div>
      <div>
         <app-start-screen *ngIf="screenType == 'start'" [levelID]="levelID" [status]="status"></app-start-screen>
         <app-stop-screen *ngIf="screenType == 'stop'" [levelID]="levelID" [status]="status"></app-stop-screen>
         <app-break-screen *ngIf="screenType == 'break'" [levelID]="levelID" [status]="status"></app-break-screen>
      </div>
      <div class="flexbox center" *ngIf="screenType !='break'">
         <i *ngIf="nextLevelExists" class="massive link angle right icon" [routerLink]="['/game', (levelID-0+1)]"></i>
         <i *ngIf="nextLevelExists != true" class="massive grey angle right icon"></i>
      </div>
   </div>

</div>
