import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core';

import { Level, GROUP_MODE, COLOR_MARKUP_FINGERBOARD, FINGER_MODE, OUTPUT_MODE, GAME_MODE, NOTE_SHOW_FINGER_MODE, TONALITIES, LevelCondition, LEVEL_CONDITION_TYPE, settingsFormToSettings, settingsToFormSettings } from "../../shared/settings";

import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: 'app-form-game-rules',
  templateUrl: './form-game-rules.component.html',
  styleUrls: ['./form-game-rules.component.scss']
})
export class FormGameRulesComponent implements OnInit {

   @Input() data: LevelCondition[];
   @Input() preloadData: LevelCondition[];
   @Output() newConditions = new EventEmitter<LevelCondition[]>();

   showButton = false;
   formConditions: FormGroup;
   conditions: LevelCondition[] = [];

  constructor(
     private fb: FormBuilder
 ) { }


  ngOnInit(): void {
     this.initForm();
  }
  ngOnChanges() {
     if(this.data != undefined && this.data.length>0) {
      this.conditions = this.data;
   } else {
      this.conditions = [];
   }
   this.checkShowButton();

 }

 private checkShowButton() {
    if(this.preloadData != undefined && this.preloadData.length > 0 && this.conditions.length == 0) {
      this.showButton = true;
   } else {
      this.showButton = false;
   }
}

preload() {
   this.conditions = JSON.parse(JSON.stringify(this.preloadData));
   this.newConditions.emit(this.conditions);
   this.checkShowButton();
}


  initForm() {
    if (this.formConditions) { return; }

    this.formConditions = this.fb.group({
      type: 0,
      number: "",
      win: true
    });
 }

 addCondition() {
   let obj = this.formConditions.value;
   obj.type = obj.type - 0;
   if(obj.win == 'false')
      obj.win = false;
   this.conditions.push(obj);
   console.log(this.conditions, obj);
   this.newConditions.emit(this.conditions);

   this.checkShowButton();
}

deleteLevelCondition(index) {
   this.conditions.splice(index, 1);
   this.checkShowButton();
}

getTypeText(type, number) {
  switch (type) {
    case 0:
     return number + " Richtige";
     break;
    case 1:
     return number + " Falsche";
     break;
    case 2:
     return number + " Richtige hintereinander";
     break;
    case 3:
     return number + " Falsche hintereinander";
     break;
    case 4:
     return number + " Dauer(sec)";
     break;
    case 5:
     return number + " Dauer bis nächster Spielzug(sec)";
     break;
    default:
     return "ERROR";
  }
}

getWinText(win) {
  return (win == true) ? "Gewinnt" : "Verliert";
}

}
