import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminConfigureComponent } from "./admin-area/admin-configure/admin-configure.component";
import { LoginComponent } from "./login/login.component";
import { CanActivateToAdminGuard }  from "./can-activate-to-admin.guard";
import { GameComponent }  from "./game/game.component";
import { AdminDivideComponent } from "./admin-area/admin-divide/admin-divide.component";
import { SignInComponent } from "./login/sign-in/sign-in.component";
import { LostPwComponent } from "./login/lost-pw/lost-pw.component";
import { NewPwComponent } from "./login/new-pw/new-pw.component";
import { CanNavigateToTestUserGuard } from "./can-navigate-to-test-user.guard";
import { LevelProgressionComponent } from "./level-progression/level-progression.component";
import { SettingsComponent } from "./settings/settings.component";

const routes: Routes = [
   {path: "", redirectTo: "login", pathMatch: "full"},
   {path: "configure", component: AdminConfigureComponent, canActivate: [CanActivateToAdminGuard]},
   {path: "configure/:levelID", component: AdminConfigureComponent, canActivate: [CanActivateToAdminGuard]},
   {path: "configure/edit/:editLevel", component: AdminConfigureComponent, canActivate: [CanActivateToAdminGuard]},
   {path: "divide", component: AdminDivideComponent, canActivate: [CanActivateToAdminGuard]},
   {path: "divide/:level", component: AdminDivideComponent, canActivate: [CanActivateToAdminGuard]},
   {path: "game/:level", component: GameComponent, canActivate: [CanNavigateToTestUserGuard]},
   {path: "settings", component: SettingsComponent, canActivate: [CanNavigateToTestUserGuard]},
   {path: "login", component: LoginComponent},
   {path: "sign-in", component: SignInComponent},
   {path: "lost-pw", component: LostPwComponent},
   {path: "new-pw/:ID/:passCode", component: NewPwComponent},
   {path: "level-progression/:level", component: LevelProgressionComponent},
   {path: "level-progression/:level", component: LevelProgressionComponent},

   {path: "**", redirectTo: "login"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
