<div (click)="showForm = !showForm" class="ui horizontal fitted divider">
  <i *ngIf="!showForm" class="angle up icon"></i>
  <i *ngIf="showForm" class="angle down icon"></i>
</div>
<div *ngIf="showForm">

  <h1>Levels Untergliedern</h1>
  <div class="ui form ">
    <form [formGroup]="formSettings" (ngSubmit)="submit()">

      <div>
        <div>
          <h4>Name</h4>
          <input type="text" formControlName="name">
        </div>
        <div>
          <h4>Von Level (inkludiert)</h4>
          <select formControlName="from">
            <option *ngFor="let level of levels" [ngValue]="level">{{level}}</option>
          </select>
        </div>
        <div>
          <h4>Bis Level (inkludiert)</h4>
          <select formControlName="to">
            <option *ngFor="let level of levels" [ngValue]="level">{{level}}</option>
          </select>
        </div>
      </div>
      <br>
      <button [disabled]="formSettings.invalid" type="submit" class="ui button">Speichern</button>
    </form>
  </div>
</div>
