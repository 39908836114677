import { Component, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { GameService } from "../../service/game.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SettingsService } from "../../../shared/settings.service";
import { LevelsService } from "../../../shared/levels.service";
import { Medal } from "../../../shared/medal";


@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss']
})
export class ScreenComponent implements OnInit {

   @Input() screenType?: String = "start"; //allowed are start, stop, break, hide
   levelID: number = 0;
   status: number = Medal.LOCKED;
   nextLevelExists = false;


  constructor(
     private game: GameService,
     private levels: LevelsService,
     private cdr: ChangeDetectorRef,
     private route: ActivatedRoute,
     private router: Router,
     private settings: SettingsService
 ) {
 }

  ngOnInit(): void {
     this.settings.medal.subscribe(medal => this.status = medal.medal);
     this.settings.ID.subscribe(ID => this.levelID = ID);
     this.settings.nextLevelExists.subscribe(exists => this.nextLevelExists = exists.nextLevelExists);

     this.game.game.subscribe(this.observe, this);
     this.game.settings.subscribe(this.observe, this);

     //when next Level Buttons are clicked -> trigger settings refresh
     this.router.events.subscribe((val) => {
        console.log("screen::onInit", this.game.settings.isLoading, this.route.snapshot.paramMap.get("level"), this.game.settings.ID);
        if(this.game.settings.isLoading != true && +this.route.snapshot.paramMap.get("level") != this.game.settings.ID) {
            this.game.settings.init();
            if(this.screenType != "start")
               this.screenType = "start"
        }
     })



     this.refresh();
  }

  ngOnChanges(): void {
     this.refresh();
 }

 refresh() {
    if(this.screenType != "start" && this.screenType != "stop" && this.screenType != "break" && this.screenType != "hide") {
      this.screenType = "hide";
    }
}

 observe(arg, action) {
   console.log("screen::observe", arg, action);
   const path = action.split("_");
   if (path[0] == "GAME") {
     switch (path[1]) {
      case "start":
         this.screenType = "hide";
         break;
      case "pause":
         this.screenType = (arg.pause) ? "break" : "hide";
         break;
      case "stop":
         this.screenType = "stop";
         break;
      case "reset":
         this.screenType = "start";
         break;
     }
   }

   if(path[0] == "SETTINGS") {
      switch(path[1]) {
         case "ID":
            this.levelID = arg;
            this.refresh();
            break;
         case "status":
         console.log("scren::setStatus - ", arg);
            this.status = arg;
            break;
      }
   }
   this.cdr.detectChanges();
}

}
