import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GameService} from "./service/game.service";
declare var Game:any;

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

   isLoading: boolean = true;

  constructor(
     private game: GameService,
     private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
     //this.isLoading = false;
     this.game.settings.subscribe(this.observe, this);
     this.game.loadingScreen$.subscribe(loading => {
        console.log("setLoadingScreen", loading);
        this.isLoading = loading});
  }

  observe(arg, action) {
   /*  if(action == "SETTINGS_isLoading") {
        this.isLoading = arg;
     }
     this.cdr.detectChanges();

     */
 }

}
