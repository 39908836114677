import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, Subject, of } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Level } from "../shared/settings";
import { ServiceTemplate } from "./service-template";
import { Medal } from "./medal";
import { GameService } from "../game/service/game.service";

@Injectable({
  providedIn: 'root'
})
export class LevelsService extends ServiceTemplate {
  protected path = "/PHP/Level/index.php";

  private data = {
     exists: [],
     status: []
 };

  constructor(
    http: HttpClient
  ) {
    super(http);

  }

  public getLevelSystem() {
    return this.post({ getDividers: true, getAllLevels: true }).pipe(
      map(data => { return { levels: data.getAllLevels, dividers: data.getDividers } })
    );
  }

  public getStatus(levelID) {
     /*
    if (levelID == 0)
      return of(false);
    if (this.data.status[levelID] != undefined)
      return of(this.data.status[levelID]);
      */
    return this.post({ status: levelID }).pipe(
      map(data => { /*this.data.status[levelID] = data.status;*/ return data.status })
    );
  }

  public getLevelProgression() {
    return this.post({ getDividers: true, getLevelProgression: true }).pipe(
      map(data => {
        return {
          levels: data.getLevelProgression.map((level) => {
            console.log(level.status, Medal.toInt(level.status));
            level.status = Medal.toInt(level.status);
            return level;
          }),
          dividers: data.getDividers
        }
      })
    );
  }

  public getLowestUnlockedLevel() {
    return this.post({ getLowestUnlockedLevel: true }).pipe(
      map(data => data.getLowestUnlockedLevel)
    );
  }
  public getHighestLevel() {
    return this.post({ getHighestLevel: true }).pipe(
      map(data => data.getHighestLevel)
    );
  }

  public newDivider(divider) {
    console.log("SAVE NEW DIVIDER", divider);
    return this.post({ newDivider: divider }).pipe(
      map(data => data.newDivider)
    );
  }

  public levelExists(ID) {
    if (ID == 0)
      return of(false);
      if(this.data.exists[ID] != undefined)
         return of(this.data.exists[ID]);
    return this.post({ levelExists: ID }).pipe(
      map(data => {this.data.exists[ID] = data.levelExists; return data.levelExists;})
    );
  }

  public deleteDivider(divider) {
    return this.post({ deleteDivider: divider }).pipe(
      map(data => data.deleteDivider)
    )
  }

  public getDividers() {
    return this.post({ getDividers: true }).pipe(
      map(data => data.getDividers)
    );
  }

  public newLevel(level: Level) {
    return this.post({ newLevel: level.ID, description: level.description, settings: level });
  }

  public getAllLevels(): Observable<any> {
    return this.post({ getAllLevels: true });
  }
  public getLevel(level: Number): Observable<any> {
    return this.post({ getAllLevels: true }).pipe(
      map(data => data.getAllLevels.filter(obj => obj.ID - 0 == level)[0])
    );
  }

  public updateLevel(ID, level): Observable<any> {
    return this.post({ updateLevel: ID, settings: level }).pipe(map(data => data.updateLevel));;
  }
  public swapLevel(level1, level2): Observable<any> {
    const ID1 = level1.ID;
    const ID2 = level2.ID;
    level1.ID = ID2;
    level2.ID = ID1;

    return this.post({ swapLevel: ID1, withLevel: ID2, settings1: level1, settings2: level2 }).pipe(map(data => data.swapLevel));;
  }
  public deleteLevel(ID): Observable<any> {
    return this.post({ deleteLevel: ID }).pipe(map(data => data.deleteLevel));
  }

}
