
  <div class="conditions">
<button class="ui button" (click)="preload()" *ngIf="showButton" type="button">Lade von vorherigem</button>
    <div class="ui grid" *ngFor="let obj of conditions; index as i">
      <div class="two wide column">
        {{getWinText(obj.win)}}
      </div>
      <div class="fourteen wide column">
        {{getTypeText(obj.type, obj.number)}}
        <button (click)="deleteLevelCondition(i)"><i class="trash icon"></i></button>
      </div>
    </div>

  </div>


  <form [formGroup]="formConditions" (ngSubmit)="addCondition()">

    <div class="inline field">
      <select formControlName="type" class="ui selection dropdown">
        <option value="0">Richtige</option>
        <option value="1">Falsche</option>
        <option value="2">Richtige hintereinander</option>
        <option value="3">Falsche hintereinander</option>
        <option value="4">Dauer (sec)</option>
        <option value="5">Dauer bis nächster Spielzug (sec)</option>
      </select>
      <input formControlName="number" type="text" size="5">&nbsp;&nbsp;
      <select formControlName="win" class="ui selection dropdown">
        <option value="true">Gewinnt</option>
        <option value="false">Verliert</option>
      </select>
      &nbsp;
      <button type="submit" class="ui button">Hinzufügen</button>
    </div>
  </form>
