<div class="ui vertical menu root">
  <a class="blue item" [ngClass]="{active: menu == 0}" (click)="(menu != 0) ? setMenu(0) : menu = -1">
    Spiel

  </a>
  <div class="active item" *ngIf="menu == 0">
    <div class="menu">
      <a class="item" *ngIf="levelID >1" [routerLink]="['/game', levelID-1]">Vorheriges Level</a>
      <a *ngIf="nextLevelExists" [routerLink]="['/game', (levelID-0+1)]" class="item">Nächstes Level</a>
    </div>
  </div>
  <a [ngClass]="{active: menu == 1}" class="blue item" (click)="(menu != 1) ? menu = 1 : menu = -1">
    Levels

  </a>
  <app-level-progression *ngIf="menu == 1" class="active item"></app-level-progression>


  <a *ngIf="isAdmin" class="blue item" [ngClass]="{active: menu == 3}" (click)="(menu != 3) ? menu = 3 : menu = -1">
    Admin Bereich

  </a>
  <div class="active item" *ngIf="menu == 3">
    <div class="menu">
      <a class="blue item" [routerLink]="'configure/'+levelID" (click)="menuService.next(false)">
        Levels Konfigurieren
      </a>
      <a class="blue item" [routerLink]="'divide/'+levelID" (click)="menuService.next(false)">
        Levels Untergliedern
      </a>
    </div>
  </div>



  <!--<a class="item">
    Einstellungen
  </a>-->
  <a class="blue item" [ngClass]="{active: menu == 4}" (click)="setMenu(4)">
    Einstellungen
  </a>

  <a class="blue item" (click)="logout()">
    Logout
  </a>
</div>
