import { Injectable } from '@angular/core';
import { GameService } from "./game.service";
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError, map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

   private secCounter = null;

   private playTime$ = new BehaviorSubject<number>(0);
   public playTime = this.playTime$.asObservable();

   public playTimeStr = this.playTime.pipe(map(time => this.toHHMMSS(time)));

   private currStat$ = new BehaviorSubject<any>(0);
   public currStat = this.currStat$.asObservable();
/*
   function (context = this) {
      return {... context.playTime$.asObservable(), getValue() {
         return context.playTime$.getValue()
      }};
   }(this);
*/
  constructor(
     private game: GameService
    ) {
       game.game.subscribe(this.observe, this);
       game.game._stats.subscribe(this.observe, this);
   }

   private toHHMMSS = function (time) {
      let addZero = function(num) {
        return num > 9 ? num : "0" + num;
      }
      return addZero(Math.floor(time / 3600)) + ":" + addZero((Math.floor(time / 60)) % 60) + ":" + addZero(time % 60);
   }

   startTimer() {
      this.secCounter = setTimeout(() => {
         this.playTime$.next(this.playTime$.getValue()+1);
         this.startTimer();
      }, 1000);
   }
   stopTimer() {
      clearTimeout(this.secCounter);
   }

   start() {
      this.playTime$.next(0);
      this.startTimer();
   }

   pause(arg) {
      if(arg.playTime) {
            this.playTime$.next(arg.playTime);
      }

      if(arg.pause) {
         this.stopTimer();
      } else {
         console.log("playTimer::restart");
         this.startTimer();
      }
   }

   stop(arg) {
      if(arg.playTime) {
            this.playTime$.next(arg.playTime);
      }
      this.stopTimer();
   }

   observe(arg, action) {
      const path = action.split("_");

      if(path[0] == "GAME") {
         switch(path[1]) {
            case "start":
            console.log("playTimer::start");
               this.start();
               break;
            case "pause":
            console.log("playTimer::pause", arg);
               this.pause(arg);
               break;
            case "stop":
               this.stop(arg);
               break;
         }
      }

      if (action == "STATISTIC_NEW") {
        this.currStat$.next(arg);
      }
   }
}
