import { Component, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Statistic } from "../../shared/statistic";
import { LevelCondition, LEVEL_CONDITION_TYPE } from "../../../shared/settings";
import { Conditions } from "../../conditions";
import { GameService } from "../../service/game.service";
import { Medal } from "../../../shared/medal";

declare const BRONZE: any;
declare const SILVER: any;
declare const GOLD: any;
declare const FINGER_MODE: any;

@Component({
  selector: 'app-start-screen',
  templateUrl: './start-screen.component.html',
  styleUrls: ['./start-screen.component.scss']
})
export class StartScreenComponent implements OnInit {

   Medal = Medal
  levelUp: Statistic = {};
  loose: Statistic = {};

  gold = "1min";
  silver = "1min 15sec";
  bronze = "1min 30sec";

  showLoose = false;
  @Input() levelID?: number = 0;
  @Input() status?: number = Medal.LOCKED;

  private conditions: Conditions;
  private fingerMode = FINGER_MODE.click;

  constructor(
     private game: GameService,
     private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.game.settings.subscribe(this.observe, this);
    this.conditions = new Conditions(this.game);
    this.conditions.subscribe(this.observe, this);
    this.refresh();
    this.fingerMode = this.game.settings.fingerMode;
    this.refreshMedails();

   /* this.conditions = new Conditions(this.game);
    this.levelUp = Conditions.compileToStat(this.conditions.winCondition);*/

  }

  ngOnChanges() {
     this.refresh();
 }

  refresh() {

    if (this.conditions == undefined || this.conditions.levelCondition == undefined)
      return true;

    this.levelUp = Conditions.compileToStat(this.conditions.levelCondition.filter(cond => cond.win == true));
    this.loose = Conditions.compileToStat(this.conditions.looseCondition);

    this.showLoose = Object.keys(this.loose).length > 0;
  }

  private timeToString(time) {
     let str = (time/60/60 >= 1) ? Math.floor(time/60/60)+"h ": "";
     str += (time/60)%60 >= 1 ? Math.floor((time/60)%60)+" min " : "";
     return str + ((time%60 > 0) ? time%60+"sec" : '');
 }

  refreshMedails() {
     this.bronze = this.timeToString(BRONZE[this.fingerMode].time);
     this.silver = this.timeToString(SILVER[this.fingerMode].time);
     this.gold = this.timeToString(GOLD[this.fingerMode].time);
 }



  observe(arg, action) {
    const path = action.split("_");
    if (action == "CONDITIONS_NEW") {
      this.refresh();
    }
    if (path[0] == "SETTINGS") {
      switch (path[1]) {
        case "fingerMode":
          this.fingerMode = arg;
          this.refreshMedails();
          break;
      }
   }
    this.cdr.detectChanges();
  }

}
