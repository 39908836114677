import { Injectable, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError, map, filter } from 'rxjs/operators';
declare var Game:any;


@Injectable({
  providedIn: 'root'
})
export class GameService {
   game: any;
   settings: any;
   stats: any;

   private loadingElements = [];

   private loadingScreenSubject$ = new BehaviorSubject<boolean>(false);
   public loadingScreen$ = this.loadingScreenSubject$.asObservable();

   constructor(
      private router: Router,
      private route: ActivatedRoute
   ) {
      this.game = new Game();
      this.settings = this.game.settings;
      this.settings.subscribe(this.observe, this)
      this.settings.init();

      // if the standart init from Game.js fails
      if(this.game.settings.isInit() == false) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
          if (this.game.settings.isInit() == false && Number.isInteger(+this.router.url.split("/")[2])) {
           this.game.settings.init(+this.router.url.split("/")[2]);
         }
        });
     }

     this.stats = this.game._stats;



   }

   setLoading(isLoading, whatIsLoading) {
      if(isLoading == true && !this.loadingElements.includes(whatIsLoading)) {
         this.loadingElements.push(whatIsLoading);
      } else if(this.loadingElements.includes(whatIsLoading)){
         this.loadingElements.splice(this.loadingElements.indexOf(whatIsLoading), 1);
      }

      if(this.loadingElements.length == 0 && this.loadingScreenSubject$.getValue()) {
         this.loadingScreenSubject$.next(false);
         console.log("SET ----------------------loadingScreen ", false);
      } else if(this.loadingElements.length > 0 && !this.loadingScreenSubject$.getValue()) {
         console.log("SET ----------------------loadingScreen ", true);
         this.loadingScreenSubject$.next(true);
      }
   }

   observe(arg, action) {
      const path = action.split("_");
      if(path[0] == "SETTINGS") {
         if(path[1] == "isLoading") {
            this.setLoading(arg, "SETTINGS");
         }
      }

   }
}
