import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { GameService } from "../game/service/game.service";
import { LevelsService } from "./levels.service";
import { Router, NavigationEnd } from "@angular/router";
import { LevelToText} from "./level-to-text";
import { filter, map } from 'rxjs/operators';
import { Medal } from "./medal";
import { Level, FINGER_MODE, GAME_MODE, GROUP_MODE } from "./settings";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private settings;

  private ID$ = new BehaviorSubject<number>(0);
  public ID = this.ID$.asObservable();
  // ID: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private nextLevelExists$ = new BehaviorSubject<any>(this.makeObj({nextLevelExists: false}));
  public nextLevelExists = this.nextLevelExists$.asObservable();

  private medal$ = new BehaviorSubject<any>(this.makeObj({medal: Medal.LOCKED}));
  public medal = this.medal$.asObservable();

  private lage$ = new BehaviorSubject<any>([]);
  public lage = this.lage$.asObservable();
  public lageStr = this.lage.pipe(map(lage => LevelToText.lage(lage)));

  private group$ = new BehaviorSubject<any>(GROUP_MODE.none);
  public group = this.group$.asObservable();
  public groupStr = this.group.pipe(map(group => LevelToText.group(group)));

  private gameMode$ = new BehaviorSubject<any>(0);
  public gameMode = this.gameMode$.asObservable();

  private fingerMode$ = new BehaviorSubject<any>(0);
  public fingerMode = this.fingerMode$.asObservable();
  public fingerModeStr = this.fingerMode.pipe(map(fingerMode => {
     console.log("makeFINGERMODE", fingerMode, this.gameMode$.getValue(), LevelToText.fingerMode(fingerMode, this.gameMode$.getValue()));
     return LevelToText.fingerMode(fingerMode, this.gameMode$.getValue())
 } ));

 private makeObj(obj) {
    obj.ID = this.ID$.getValue();
    return obj;
}

  constructor(
    private game: GameService,
    private levels: LevelsService,
    private router: Router,
  ) {
this.lage.subscribe(lage => console.log("SET_LAGE", lage));
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.router.url.split("/")[1] == "game" && Number.isInteger(+this.router.url.split("/")[2]) && +this.router.url.split("/")[2] != this.ID$.getValue()) {
        this.ID$.next(+this.router.url.split("/")[2]);
      }
    });

    this.ID.subscribe((ID) => {
      this.game.settings.init(ID);

      this.game.setLoading(true, "loadMedal");
      this.levels.getStatus(ID).subscribe((status) => {
        this.medal$.next(this.makeObj({medal: Medal.toInt(status)}));
        this.game.setLoading(false, "loadMedal");
      });
      this.levels.levelExists(ID).subscribe(exists => this.nextLevelExists$.next(this.makeObj({nextLevelExists: exists})));
    })

    this.game.settings.subscribe(this.observe, this);

    // INIT
   this.fingerMode$.next(this.game.settings.fingerMode);
   this.lage$.next(this.game.settings.lagen);
   this.group$.next(this.game.settings.group);
   this.gameMode$.next(this.game.settings.gameMode);

   // because fingerModeStr depends on GameMode
   this.gameMode.subscribe(() => this.fingerMode$.next(this.fingerMode$.getValue()));


  }

  getID() {
    return this.ID$.getValue();
  }

  getMedal() {
    return this.medal$.getValue().medal;
  }

  getFingerModeStr() {
     return LevelToText.fingerMode(this.fingerMode$.getValue(), this.gameMode$.getValue())
 }

 getLageStr() {
    return LevelToText.lage(this.lage$.getValue());
}


  observe(arg, action) {
      console.log("SettingsService::observe", arg, action);
    const path = action.split("_");
    if (path[0] == "SETTINGS") {
      console.log("SettingsService::observe", arg, action);
      switch (path[1]) {
        case "medal":
          if (this.medal$.getValue().medal < Medal.toInt(arg))
            this.medal$.next(this.makeObj({medal: Medal.toInt(arg)}));
            break;
         case "lagen":
            this.lage$.next(arg);
            break;
         case "group":
            this.group$.next(+arg);
            break;
         case "fingerMode":
            this.fingerMode$.next(+arg);
            break;
         case "gameMode":
            this.gameMode$.next(+arg);
            break;
      }
    }
  }

  /*
      this.game.settings.subscribe(this.observe, this);

      // if the standart init from Game.js
      if(this.game.settings.isInit() == false) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
          if (this.game.settings.isInit() == false && Number.isInteger(+this.router.url.split("/")[2])) {
            this.game.settings.init(+this.router.url.split("/")[2]);
         }
        });
     }

      if(+this.game.settings.ID != -1)
      this.ID.next(this.game.settings.ID);

      // wenn ID refreshed wird refresh die folgenden Datentypen ...
      this.ID.subscribe((ID) => {
        if (Number.isInteger(ID) && ID > 0) {
          this.levels.levelExists(ID + 1).subscribe(exists => {
            this.nextLevelExists$.next(exists);
          });
        }
      })
    }


  }*/
}
