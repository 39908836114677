<h3>{{name}}</h3>
<table class="ui definition table">
  <tbody>
     <tr *ngIf="showPlayTime == true">
       <td>Spielzeit</td>
       <td>{{playTimeStr}}</td>
     </tr>
     <tr *ngIf="printStat.duration != undefined">
        <td>Zeit</td>
        <td>{{printStat.duration}}</td>
     </tr>
     <tr *ngIf="printStat.timeUntilNextMove != undefined">
        <td>Zeit bis zum nächsten Zug</td>
        <td>{{printStat.timeUntilNextMove}}</td>
     </tr>
     <tr *ngIf="printStat.rightOnes != undefined">
        <td>Richtige</td>
        <td>{{printStat.rightOnes}}</td>
     </tr>
     <tr *ngIf="printStat.wrongOnes != undefined">
        <td>Falsche</td>
        <td>{{printStat.wrongOnes}}</td>
     </tr>
     <tr *ngIf="printStat.rightOnesInARow != undefined">
        <td>Richtige in Folge</td>
        <td>{{printStat.rightOnesInARow}}</td>
     </tr>
     <tr *ngIf="printStat.wrongOnesInARow != undefined">
        <td>Falsche in Folge</td>
        <td>{{printStat.wrongOnesInARow}}</td>
     </tr>

  </tbody>
  </table>
