import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable} from 'rxjs';
import { AuthService } from "./shared/auth.service";
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CanNavigateToTestUserGuard implements CanActivate {
   constructor(
      private auth: AuthService,
      private router: Router
   ) { }

     canActivate(
       route: ActivatedRouteSnapshot,
       state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
         //return true;
         return this.auth.access("TESTUSER").pipe(
            map(access => {
               console.log("access::try", access);
               if(access == false)
                  return this.router.parseUrl("/login");
               return access;
            })
         )
     }

   }
