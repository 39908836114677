<app-dimmed-message [show]="isLoading"></app-dimmed-message>
<!--<div class="ui active dimmer" *ngIf="isLoading">
  <div class="ui large text loader">Daten werden geladen ...</div>
</div>-->



<app-screen></app-screen>
<!--
<app-start-screen class="startScreen"></app-start-screen>

<app-break-screen class="breakScreen" style="display: none"></app-break-screen>

<app-stop-screen class="stopScreen" style="display: none"></app-stop-screen>
-->
<div class="gameBoard">
  <div class="gameField" cdkDropListGroup>
    <app-finger-board class="fingerBoard"></app-finger-board>
    <app-help-lines></app-help-lines>
    <div class="bgIMG"><img src="assets/geige.png"></div>
    <div class="currentLageBorder currLage0">
      <!--<div class="section1">Finger 1</div>
                  <div class="section2">Finger 2</div>
                  <div class="section3">Finger 3</div>
                  <div class="section4"></div>-->
      <div class="currLage">
        <!--<h4>Lage: <label class="currentLageNum">1</label></h4>-->
          <div class="setCurrentLage">
            <button class=" ui icon button currentLageDown"><i class="double angle up icon"></i></button>
          </div>
          <div class="lageH4">Lage: <label class="currentLageNum">1</label></div>
          <div class="setCurrentLage">
            <button class="ui icon button currentLageUp"><i class="double angle down icon"></i></button>
          </div>


      </div>
      <app-drag-fingers></app-drag-fingers>
    </div>


  </div>
</div>
<app-left-menu class="boxLeft"></app-left-menu>
<div class="noteBoard">
  <div class="notef">
    <div>&nbsp;<br>
      <img class="linesBGPic" src="assets/violinkey.png">
    </div>
    <div class="notesDiv">
      <div class="linesFill">
        &nbsp;<br>
        <img src="assets/lines.png">
      </div>
      <div class="notes">
        <div class="note"></div>
      </div>
      <div class="linesFill">
        &nbsp;<br>
        <img src="assets/lines.png">
      </div>

    </div>
    <div>
      &nbsp;<br>
      <img class="linesBGPic" src="assets/endline.png">
    </div>

  </div>
  <!--<div class="letters">

          </div>-->
</div>
