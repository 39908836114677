export class COLOR_MARKUP_FINGERBOARD {
  static none = 0;
  static helpLines = 1;
  static cDur = 2;
  static bDur = 3;
  static dDur = 4;
  static fDur = 5;
  static gDur = 6;
}

export class OUTPUT_MODE {
  static picAndLetter = 0;
  static pic = 1;
  static letter = 2;
  static mix = 3;
};

export class GROUP_MODE {
  static none = -1;
  static group1 = 0;
  static group2 = 1;
  static group3 = 2;
}

export class FINGER_MODE {
  static click = 0;
  static drop = 1;
  static clickOnFinger = 2;
  static fingerHoldAndClick = 3;
};

export class TONALITIES {
  static cDur = "cDur";
  static bDur = "bDur";
  static dDur = "dDur";
  static fDur = "fDur";
  static gDur = "gDur";
  static invCdur = "invCdur";
  static whole = "whole";
}

export class NOTE_SHOW_FINGER_MODE {
  static none = 0;
  static show = 1;
  static mix = 2;
  static showWhenLageChange = 3;
}


export class GAME_MODE {
  static notSet = -1;
  static findNote = 0;
  static multipleFingerBoard = 1;
  static multipleNoteBoard = 2;
  static playPiece = 3;
}


export class LEVEL_CONDITION_TYPE {
  static rightOnes = 0;
  static wrongOnes = 1;
  static rightOnesInARow = 2;
  static wrongOnesInARow = 3;
  static duration = 4;
  static timeUntilNextMove = 5;
}

export interface LevelCondition {
   win: boolean,
   type: LEVEL_CONDITION_TYPE,
   number: Number
}



export function settingsFormToSettings(settings) {
   let set = JSON.parse(JSON.stringify(settings));
  let arr = [];
  if (set.lagen.lage1)
    arr.push(0);
  if (set.lagen.lage2)
    arr.push(1);
  if (set.lagen.lage3)
    arr.push(2);
  if (set.lagen.lage4)
    arr.push(3);
  if (set.lagen.lage5)
    arr.push(4);
  if (set.lagen.lage6)
    arr.push(5);
set.lagen = arr;
console.log("SAVE ", set);
return set;
}


export function settingsToFormSettings(settings) {
   let set = JSON.parse(JSON.stringify(settings));
   set.lagen = {
      lage1: (set.lagen.includes(0)) ? true : false,
      lage2: (set.lagen.includes(1)) ? true : false,
      lage3: (set.lagen.includes(2)) ? true : false,
      lage4: (set.lagen.includes(3)) ? true : false,
      lage5: (set.lagen.includes(4)) ? true : false,
      lage6: (set.lagen.includes(5)) ? true : false
   }

   return set;
}


export interface Settings {
  //Noten im Spiel
  lagen: Number[],
  group: GROUP_MODE,
  tonality: TONALITIES,
  basicTone: Boolean,

  //Geige
  rotation: Number,
  showHelpLines: Boolean,
  showFinger: Boolean,
  showCssDur: Boolean,
  colorMarkupPoints: COLOR_MARKUP_FINGERBOARD,

  numNotesFingerBoard: Number,
  playingLageAuto: Boolean,
  fingerMode: FINGER_MODE,

  //Notenzeile
  outputMode: OUTPUT_MODE,
  numNotesNoteBoard: Number,
  showFingerNum: NOTE_SHOW_FINGER_MODE,

  //Spieltyp
  notesTurnable: Boolean,
  defaultShowNames: Boolean,
  tippsActive: Boolean,
  showTippAfterNumWrongOnes: Number,
  gameMode: GAME_MODE,

  //Konditionen
  endCondition: LevelCondition[],
  levelCondition: LevelCondition[],
  bronzeCondition: LevelCondition[],
  silverCondition: LevelCondition[],
  goldCondition: LevelCondition[]
}

export interface Level extends Settings {
  ID: Number,
  description: String,
  stat: Statistic
}

export interface Statistic {
   numPlayed: Number,
   won: Number,
   avrTime: Number,
   avrTimeWon: Number,
   avrTimeLost: Number
}




const TYP_SHOW_MATRIX = {
  point: true,
  name: false
}

const NOTE_TYP = {
  fingerBoard: 0,
  noteBoard: 1,
  normal: 2
}
