import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GameService } from '../service/game.service';
import { MenuService } from "../../shared/menu.service";


@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  showMenu = false;
  showPause = false;
  tippsActive = true;
  stopDisabled = true;

  constructor(
    private game: GameService,
    private cdr: ChangeDetectorRef,
    public menu: MenuService
  ) { }

  ngOnInit(): void {
    this.game.game.subscribe(this.observe, this);
    this.game.settings.subscribe(this.observe, this);
    this.tippsActive = this.game.settings.tippsActive;
  }

  observe(arg, action) {
    const path = action.split("_");
    console.log("LEFTMENU::observe", arg, action);
    if (path[0] == "GAME") {
      switch (path[1]) {
        case "start":
          this.showPause = true;
          this.stopDisabled = false;
          break;
        case "pause":
          this.showPause = !arg.pause
          break;
        case "stop":
          this.stopDisabled = true;
          this.showPause = false;
          break;
      }
    }
    if (path[0] == "SETTINGS") {
      switch (path[1]) {
        case "tippsActive":
          this.tippsActive = arg;
          break;
      }
    }
    this.cdr.detectChanges();
  }

}
