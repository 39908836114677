import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GameService } from "../service/game.service";

@Component({
  selector: 'app-help-lines',
  templateUrl: './help-lines.component.html',
  styleUrls: ['./help-lines.component.scss']
})
export class HelpLinesComponent implements OnInit {

   active: true;

  constructor(
     private game: GameService,
     private cdr: ChangeDetectorRef
 ) {
this.game.settings.subscribe(this.observe, this);
this.active = this.game.settings.showHelpLines;
}

  ngOnInit(): void {
  }

  observe(arg, action) {
    const path = action.split("_");
    if (path[0] == "SETTINGS") {
      if (path[1] == "showHelpLines") {
         console.log("HELPLINESCOMPONENT::showHelpLines", arg);
        this.active = arg;
      }
    }
    this.cdr.detectChanges();
  }

}
