import { Level, FINGER_MODE, GAME_MODE, GROUP_MODE } from "./settings";

export class LevelToText {

  private level: Level;

  constructor(l: Level) {
    this.level = l;
  }
/*
  fingerMode() {
    return LevelToText.fingerMode(this.level.fingerMode);
  }

  gameMode() {
    return LevelToText.gameMode(this.level.gameMode);
  }

  */

  static toString(argName, arg) {

    if (LevelToText[argName] != undefined) {
      return LevelToText[argName](arg);
    }
    return undefined;
  }


  static group(group: GROUP_MODE) {
    switch (group) {
      case GROUP_MODE.none:
        return "";
      case GROUP_MODE.group1:
        return "Gruppe 1";
      case GROUP_MODE.group2:
        return "Gruppe 2";
      case GROUP_MODE.group3:
        return "Gruppe 3";
      default:
        "undefined group";
    }
  }

  static lage(lage) {
     let format = (lage) => {
        let str = "";
        for(let i=0;i<lage.length;i++) {
          (i>0)
          str +=(i>0) ? ", "+(lage[i]+1) : " "+(lage[i]+1);
        }
        return str;
     }

     return "Lage"+format(lage);
  }

  static gameMode(level:any) {
     level.gameMode = level.gameMode-0;
     switch(level.gameMode) {
        case GAME_MODE.findNote:
           return "Finde die Note";
           break;
        case GAME_MODE.multipleFingerBoard:
           return "Multiple Choice Geige";
        case GAME_MODE.multipleNoteBoard:
           return "Multiple Choice Noten";
        default:
           return "undefined gameMode"+level.gameMode;
     }
  }

  static fingerMode(fingerMode: FINGER_MODE, gameMode: GAME_MODE) {
    switch (fingerMode) {
      case FINGER_MODE.drop:
        return "Finger ziehen";
        break;
      case FINGER_MODE.click:
         if(gameMode == GAME_MODE.multipleNoteBoard)
            return "Klicken auf Notenzeile";
        return "Klicken";
        break;
      case FINGER_MODE.clickOnFinger:
        return "Finger-Klick";
        break;
      case FINGER_MODE.fingerHoldAndClick:
        return "Finger-Halten-Ziehen";
        break;
      default:
        throw "ERROR: undefined fingerMode";
    }
  }
}
