<div>
   <app-screen-header [status]="status"></app-screen-header>
   <h1>Pause</h1>

   <button class="startGame ui black button">Weiterspielen</button>
   <br>
   <br>
   <app-statistic></app-statistic>
   <br>

</div>
