import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { AuthService } from "../../shared/auth.service";

@Component({
  selector: 'app-lost-pw',
  templateUrl: './lost-pw.component.html',
  styleUrls: ['./lost-pw.component.scss']
})
export class LostPwComponent implements OnInit {

   lostPw: FormGroup;
   showMessage = false;

  constructor(
     private fb: FormBuilder,
     private auth: AuthService
 ) { }

  ngOnInit(): void {
     this.initForm();
  }

  private initForm() {
    this.lostPw = this.fb.group({
      eMail: ["", [Validators.required, Validators.email]],
    });
  }

  submit() {
     this.showMessage = true;
     this.auth.lostPw(this.lostPw.get("eMail").value).subscribe((data) => {});
 }

}
