import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from "@angular/forms";
import { filter, map} from "rxjs/operators"

@Component({
  selector: 'app-form-messages',
  templateUrl: './form-messages.component.html',
  styleUrls: ['./form-messages.component.scss']
})
export class FormMessagesComponent implements OnInit {

   @Input() control: AbstractControl;
   @Input() controlName: string;


private allMessages = {
   username: {
      minlength: "Der Benutzername muss mindestens 4 Zeichen enthalten.",
      exists: "Dieser Username exestiert bereits"
   },
   eMail: {
      required: "Es muss eine E-Mail Adresse angegeben werden.",
      email: "Es muss eine gültige E-Mail Adresse angegeben werden.",
      exists: "Diese Email-Adresse exestiert bereits"
   },
   password: {
      required: "Es muss ein Passwort angegeben werden.",
      minlength: "Das Passwort muss mindestens 6 Zeichen enthalten",
   }
}

  constructor() {  }

  ngOnInit(): void {
  }

  errorsForControl(): string[] {
     const messages = this.allMessages[this.controlName];
          if(!this.control || !this.control.errors || !messages || !this.control.dirty) {
             return null;
          }
          console.log("form-messages::errorsForControl", this.controlName);

          console.log("fomr-messages::errorsForcontrol", Object.keys(this.control.errors))

          return Object.keys(this.control.errors).map(err => messages[err]);


/*
     const messages = this.allMessages[this.controlName];
     if(!this.control || !this.control.dirty || !this.control.errors  || !messages) {
      return null;
   }

     return Object.keys(this.control.errors).filter((data) => messages[data] != undefined).map(err => messages[err])*/
 }
}
