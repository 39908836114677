import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { LevelsService } from "../../shared/levels.service";
import { NgEventBus } from 'ng-event-bus';


@Component({
  selector: 'app-form-divider',
  templateUrl: './form-divider.component.html',
  styleUrls: ['./form-divider.component.scss']
})
export class FormDividerComponent implements OnInit {
   showForm = true;
   levels: Number[];


   formSettings: FormGroup;

  constructor(
     private fb: FormBuilder,
     private ls: LevelsService,
     private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
     this.initForm();
     this.ls.getAllLevels().subscribe(data => {
        console.log(data.getAllLevels);
        this.levels = data.getAllLevels.map(level => level.ID-0);
        console.log(this.levels);
     })
  }


  initForm() {
    if (this.formSettings) { return; }

/*
    this.formLevelCondition = this.fb.group({
      type: 0,
      number: "",
      win: true
   });*/

    this.formSettings = this.fb.group({
      name: "",
      from: ['', [Validators.required]],
      to: ['', [Validators.required]]
   }, {validators: [this.numberCheck]});


}

public numberCheck(control: FormGroup): ValidationErrors | null {
  if(control.value.from <= control.value.to) {
      return null;
  }
  return {numberCheck: {valid: false}};
}

  submit() {
     let divider = this.formSettings.value;
     this.ls.newDivider(divider).subscribe(success => {
        if (success) {
          alert("Datensatz erfolgreich eingefügt");
          this.eventBus.cast("refresh", true);
       } else {
          alert("Es ist ein Fehler aufgetreten");
       }
     }, error => alert("Es ist ein Fehler aufgetreten"));

 }

}
