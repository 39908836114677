import { Component, OnInit } from '@angular/core';
import { SettingsService } from "../shared/settings.service";
import { AuthService } from "../shared/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuService } from "../shared/menu.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {


   menu = 1;
   nextLevelExists = false;
   levelID = 0;
   isAdmin = false;

  constructor(
     private settings: SettingsService,
     private auth: AuthService,
     private router: Router,
     private route: ActivatedRoute,
     private menuService: MenuService
 ) { }

  ngOnInit(): void {
     this.settings.nextLevelExists.subscribe(exists => this.nextLevelExists = exists.nextLevelExists);
     this.settings.ID.subscribe(ID => this.levelID = ID);
     this.auth.isAdmin().subscribe(isAdmin => this.isAdmin = isAdmin);
  }

  setMenu(num) {
     if(num == 0) {
        this.menu = 0;
        if(this.levelID != 0)
         this.router.navigateByUrl("game/"+this.levelID);
     }
     if(num == 4) { // Settings
        this.menu = 4;
        this.router.navigateByUrl("settings");
        this.exitMenu();
     }
 }

  logout() {
     this.auth.logout();
      this.router.navigate(['login'], { relativeTo: this.route });
      this.exitMenu();
 }
   exitMenu() {
      this.menuService.next(false);
   }

}
