import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

export class ServiceTemplate {

   protected path = "/PHP/Login/index.php";
   //private url = "https://configure.violearn.at";
   private url = "";

   constructor(
     protected http: HttpClient
   ) { }


   protected errorHandler(error: HttpErrorResponse): Observable<any> {
     console.error("Fehler aufgetreten");
     return throwError(error);
   }

   protected post(params): Observable<any> {
     //const headers = { 'content-type': 'application/json', 'Access-Control-Allow-Origin':'*' }
      const headers = { 'content-type': 'application/json' }
     const body = JSON.stringify(params);

     return this.http.post(this.url+this.path, body, { 'headers': headers }).pipe(
       retry(3),
       catchError(this.errorHandler),
     );
   }
}
