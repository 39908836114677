<div class="ui middle aligned center aligned grid">
  <div class="column">
    <h2 class="ui blue image header flexbox center">
      <img src="assets/Logo.png" class="image" alt="LOGO"><br><br>
      <div class="content flexbox center">
        Registrierung
      </div>
    </h2>
    <form class="ui large form" [formGroup]="signIn" (ngSubmit)="createAccount()">
      <div class="ui stacked segment">

         <div class="field" [formGroup]="signIn.get('name')">
            <label class="ui aligned left header">Name (optional)</label>
                 <div class="ui two column grid">

                    <div class="column">
                       <input type="text" formControlName="firstName" placeholder="Vorname">
                     </div>
                     <div class="column">
                         <input type="text" formControlName="lastName" placeholder="Nachname">
                      </div>
                 </div>
         </div>

        <div class="field">
           <label class="ui aligned left header">Benutzername (optional)</label>
          <div class="ui right icon input">
            <i class="user icon"></i>
            <input type="text" formControlName="username" placeholder="Benutzername">
          </div>
          <app-form-messages [control]="signIn.get('username')" controlName="username"></app-form-messages>
        </div>

        <div class="field">
           <label for="eMail" class="ui aligned left header">E-Mail</label>
          <div class="ui right icon input">
            <i class="user icon"></i>
            <input type="email" formControlName="eMail" placeholder="E-Mail Adresse">
          </div>
         <app-form-messages [control]="signIn.get('eMail')" controlName="eMail"></app-form-messages>
        </div>

        <div class="field">
           <label for="username" class="ui aligned left header">Passwort</label>
          <div class="ui right icon input">
            <i class="lock icon"></i>
            <input type="password" formControlName="password" placeholder="Password">
          </div>
          <app-form-messages [control]="signIn.get('password')" controlName="password"></app-form-messages>
        </div>





<!--[disabled]="signIn.invalid"-->
        <button class="ui fluid large blue submit button" type="submit">Account anlegen</button>
      </div>



      <div class="ui error message active">ERROR</div>

    </form>

    <div class="ui message">
      Du hast bereits einen Account? <a routerLink="/login">Zum Login</a>
    </div>
  </div>
</div>

<app-dimmed-message [show]="loading"></app-dimmed-message>

<app-dimmed-message [show]="createdUserMsg || errorMsg" [time]="3500" [info]="infoStr" (finished)="routeLogin()"></app-dimmed-message>
