<div (click)="showForm = !showForm" class="ui horizontal fitted divider">
  <i *ngIf="!showForm" class="angle up icon"></i>
  <i *ngIf="showForm" class="angle down icon"></i>
</div>
<div *ngIf="showForm">
  <h1 *ngIf="edit == false">Neues Level erstellen</h1>
  <h1 *ngIf="edit">Level {{formSettings.get('ID').value}} bearbeiten</h1>

  <div *ngIf="edit">
    ID: {{level.ID}} | Gespiel: {{level.stat.numPlayed}} mal | Gewonnene Spiele: {{level.stat.won}} | Durchschnittliche Zeit: {{level.stat.avrTime}} | Durchschnittliche Zeit für Gewonnene Spiele: {{level.stat.avrTimeWon}} | Durchschnittliche Zeit für
    Verlorene Spiele: {{level.stat.avrTimeLost}}
  </div>
  <div *ngIf="IDexists && edit == false">
    Diese ID exestiert bereits!
  </div>

  <div class="ui tabular menu">
    <a [ngClass]="{active: active == 5}" (click)="active = 5" class="item">Allgemein</a>
    <a [ngClass]="{active: active == 0}" (click)="active = 0" class="item">Noten im Spiel</a>
    <a [ngClass]="{active: active == 1 }" (click)="active = 1" class="item">Geige - Griffbrett</a>
    <a [ngClass]="{active: active == 2 }" (click)="active = 2" class="item">Notenzeile</a>
    <a [ngClass]="{active: active == 3 }" (click)="active = 3" class="item">Spieltyp</a>
    <a [ngClass]="{active: active == 4 }" (click)="active = 4" class="item">Spiel Endbedingung</a>
    <!--<a [ngClass]="{active: active == 6 }" (click)="active = 6" class="item">Bronze Medaille</a>
    <a [ngClass]="{active: active == 7 }" (click)="active = 7" class="item">Silber Medaille</a>
    <a [ngClass]="{active: active == 8 }" (click)="active = 8" class="item">Gold Medaille</a>-->
  </div>
  <div class="settingsMenu ui form">
    <form [formGroup]="formSettings" (ngSubmit)="submit()">
      <!-- Allgemein -->
      <div [ngClass]="{hide: active != 5}" class="flexbox">
        <div>
          <h4>Level ID</h4>
          <input type="text" formControlName="ID">
        </div>
        <!--<div>
          <h4>Nach Level einfügen</h4>
          <select formControlName="insertAfter">
             <option *ngFor="let ID of IDs" [ngValue]="ID">{{ID}}</option>
            <option>No Entry</option>
         </select>
        </div>-->
        <div>
          <h4>Beschreibung</h4>
          <textarea cols="50" rows="4" formControlName="description"></textarea>
        </div>
      </div>
      <!-- Noten im Spiel -->
      <div [ngClass]="{hide: active != 0}" class="flexbox">
        <div>
          <h4>Lage</h4>
          <div formGroupName="lagen">
            <div class="ui checkbox">
              <input type="checkbox" formControlName="lage1">
              <label>1. Lage</label>
            </div><br>
            <div class="ui checkbox">
              <input type="checkbox" formControlName="lage2">
              <label> 2. Lage</label>
            </div><br>
            <div class="ui checkbox">
              <input type="checkbox" formControlName="lage3">
              <label> 3. Lage</label>
            </div><br>
            <div class="ui checkbox">
              <input type="checkbox" formControlName="lage4">
              <label> 4. Lage</label>
            </div><br>
            <div class="ui checkbox">
              <input type="checkbox" formControlName="lage5">
              <label> 5. Lage</label>
            </div><br>
            <div class="ui checkbox">
              <input type="checkbox" formControlName="lage6">
              <label> 6. Lage</label>
            </div>
          </div>
        </div>
        <div>
          <h4>Gruppe </h4>
          <select formControlName="group" class="ui selection dropdown">
            <option value="-1">Alle Töne</option>
            <option value="0">Gruppe 1</option>
            <option value="1">Gruppe 2</option>
            <option value="2">Gruppe 3</option>
          </select><br><br>
        </div>
        <div>
          <h4>Tonart </h4>
          <select formControlName="tonality" class="ui selection dropdown">
            <option value="whole">Alle Töne</option>
            <option value="cDur">C-Dur</option>
            <option value="bDur">B-Dur</option>
            <option value="dDur">D-Dur</option>
            <option value="fDur">F-Dur</option>
            <option value="gDur">G-Dur</option>
            <!-- <option>Nur Halbtöne</option>-->
          </select>
        </div>
        <div>
          <h4>Leere Seiten</h4>
          <div class="ui checkbox">

            <input type="checkbox" formControlName="basicTone">
            <label>Leere Seiten</label>
          </div>
        </div>
      </div>

      <!-- Geige - Griffbrett -->
      <div [ngClass]="{hide: active != 1}" class="flexbox marginBig">
        <div>
          <h4>Darstellung</h4>
          <div class="inline field">
            Rotation:
            <input formControlName="rotation" size="2">
            Grad
          </div>

          <div class="ui checkbox">
            <input type="checkbox" formControlName="showFinger">
            <label>zeige Finger</label>
          </div><br>
          <div class="ui checkbox">
            <input type="checkbox" formControlName="showHelpLines">
            <label>zeige Hilfslinien</label>
          </div><br>
          <div class="ui checkbox">
            <input type="checkbox" formControlName="showCssDur">
            <label>zeige C-Dur</label>
          </div><br><br>

          Farbliche Markierung nach
          <select formControlName="colorMarkupPoints" class="ui selection dropdown">
            <option value="0">keine</option>
            <option value="1">Hilfslinien</option>
            <option value="2">Finger in cDur</option>
            <option value="3">Finger in bDur</option>
            <option value="4">Finger in dDur</option>
            <option value="5">Finger in fDur</option>
            <option value="6">Finger in gDur</option>
          </select><br>
        </div>
        <div>
          <h4>Spiel</h4>
          <div class="inline field">
            bei Multiple Choise - Anzahl der Vorschläge: <input size="2" formControlName="numNotesFingerBoard">
          </div>
          <div class="ui checkbox">
            <input type="checkbox" formControlName="playingLageAuto">
            <label>wähle die Lage automatisch</label>
          </div>
          <br><br>
          Auswahl Modus:
          <select formControlName="fingerMode" class="ui selection dropdown">
            <option value="0">Klicken</option>
            <option value="1">Finger ziehen</option>
            <option value="2">Finger tippen</option>
            <option value="3">Finger greifen (mobil)</option>
          </select><br>

        </div>
      </div>

      <!-- Notenzeile -->
      <div [ngClass]="{hide: active != 2}">
        <div class="flexbox wrap">
          <div>
            <h4>Anzahl der gezeigten Noten</h4> <input size="5" formControlName="numNotesNoteBoard">
          </div>
          <div>
            <h4>Schreib/Notenschrift</h4>
            <select formControlName="outputMode" class="ui selection dropdown">
              <option value="3">Gemischt</option>
              <option value="2">Schreibschrift</option>
              <option value="1">Notenschrift</option>
              <option value="0">Zeige Beide</option>
            </select>
          </div>
          <div>
            <h4>Art der Fingerbeschriftung</h4>
            <select formControlName="showFingerNum" class="ui selection dropdown">
              <option value='0'>keine Fingerbeschriftung</option>
              <option value='3'>Fingerbeschriftung bei Lagewechsel</option>
              <option value='1'>immer eine Fingerbeschriftung</option>
              <option value='2'>zufällige Fingerbeschriftung</option>
            </select>
          </div>
        </div>
      </div>

      <!-- Spieltyp -->
      <div [ngClass]="{hide: active != 3}">
        <div class="ui checkbox">
          <input type="checkbox" formControlName="notesTurnable">
          <label>Spiele mit Noten Namen am Griffbrett</label>
        </div><br>
        <div class="ui checkbox">
          <input type="checkbox" formControlName="defaultShowNames">
          <label>Starte mit allen Notennamen sichtbar</label>
        </div><br><br>

        <div class="ui checkbox">
          <input type="checkbox" formControlName="tippsActive">
          <label>Tipps Aktiv</label>
        </div><br>

        <div class="inline field">
          Zeige Tipp nach <input type="text" size="3" formControlName="showTippAfterNumWrongOnes"> falschen Versuchen
        </div>

        <h4>Spielart</h4>
        <select formControlName="gameMode" class="ui selection dropdown">
          <option value="0">Finde die Note</option>
          <option value="1">Multiple Choise - Finde auf der Geige</option>
          <option value="2">Multiple Choise - Finde auf der Notenzeile</option>
          <option value="3">Stück Mitspielen</option>
        </select>

      </div>


      <!-- Endbedingung
  <div [ngClass]="{hide: active != 4}">
    <div class="endCondition">

      <div class="ui grid" *ngFor="let obj of endCondition; index as i">
        <div class="two wide column">
          {{getWinText(obj.win)}}
        </div>
        <div class="fourteen wide column">
          {{getTypeText(obj.type, obj.number)}}
          <button (click)="deleteLevelCondition(i)"><i class="trash icon"></i></button>
        </div>
      </div>

    </div>


    <form [formGroup]="formLevelCondition" (ngSubmit)="addCondition()">

      <div class="inline field">
        <select formControlName="type" class="ui selection dropdown">
          <option value="0">Richtige</option>
          <option value="1">Falsche</option>
          <option value="2">Richtige hintereinander</option>
          <option value="3">Falsche hintereinander</option>
          <option value="4">Dauer (sec)</option>
          <option value="5">Dauer bis nächster Spielzug (sec)</option>
        </select>
        <input formControlName="number" type="text" size="5">&nbsp;&nbsp;
        <select formControlName="win" class="ui selection dropdown">
          <option value="true">Gewinnt</option>
          <option value="false">Verliert</option>
        </select>
        &nbsp;
        <button type="submit" class="ui button">Hinzufügen</button>
      </div>
    </form>
  </div>
  -->

      <app-form-game-rules [data]="levelCondition" (newConditions)="newLevelCondition($event)" [ngClass]="{hide: active != 4}">
      </app-form-game-rules>
<!--
      <app-form-game-rules [preloadData]="levelCondition" [data]="bronzeCondition" (newConditions)="newBronzeCondition($event)" [ngClass]="{hide: active != 6}">
      </app-form-game-rules>
      <app-form-game-rules [preloadData]="bronzeCondition" [data]="silverCondition" (newConditions)="newSilverCondition($event)" [ngClass]="{hide: active != 7}">
      </app-form-game-rules>
      <app-form-game-rules [preloadData]="silverCondition" [data]="goldCondition" (newConditions)="newGoldCondition($event)" [ngClass]="{hide: active != 8}">
      </app-form-game-rules>
-->
      <div class="ui divider"></div>

      <button [disabled]="formSettings.invalid" class="ui button" type="submit" *ngIf="edit == false">Neuen Eintrag Erstellen</button>
      <button [disabled]="formSettings.invalid" class="ui button" type="submit" *ngIf="edit">Änderungen Speichern</button>

    </form>
  </div>
</div>
