import { Component, OnInit } from '@angular/core';
import { MenuService } from "../shared/menu.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "../shared/user";
import { AuthService } from "../shared/auth.service";
import { filter, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators'
import { EmailValidatorService } from "../login/shared/Validators/email-validator.service";
import { UsernameValidatorService } from "../login/shared/Validators/username-validator.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

   myData: FormGroup;
   accessData: FormGroup;
   pwData: FormGroup;

   menu = 0;

   infoMsg = "Änderungen erfolgreich gespeichert";
   showMsg = false;

  constructor(
     private fb: FormBuilder,
     private emailValidator: EmailValidatorService,
     private usernameValidator: UsernameValidatorService,
     public menuService: MenuService,
     public auth: AuthService
 ) { }

  ngOnInit(): void {
     this.initForms();
     this.setData();
  }

  setData() {
    this.auth.getAllData().subscribe(data => {
      console.log("Settings::userdata ", data);

      this.usernameValidator.exept(data.username);
      this.emailValidator.exept(data.eMail);

      this.myData.patchValue({name: data});
      this.accessData.patchValue(data);
   });
 }

 saveMyData() {
    this.auth.editName(this.myData.value.name).subscribe((success) => {
      if(!success) {
         this.infoMsg = "Die Änderungen konnten nicht gespeichert werden";
      }
      this.showMsg = true;
   });
}

saveAccessData() {
   this.auth.editUsernameAndEmail(this.accessData.value).subscribe((success) => {
     if(!success) {
        this.infoMsg = "Die Änderungen konnten nicht gespeichert werden";
     }
     this.showMsg = true;
  });
}
savePwData() {
   this.auth.editPassword(this.pwData.value).subscribe((success) => {
     if(!success) {
        this.infoMsg = "Die Änderungen konnten nicht gespeichert werden";
     }
     this.showMsg = true;
  });
}

clearTimeout() {
   this.infoMsg = "Änderungen erfolgreich gespeichert";
   this.showMsg = false;
}

  initForms() {
    this.myData = this.fb.group({
      name: this.fb.group({
        firstName: "",
        lastName: "",
      }),
    });

    this.accessData = this.fb.group({

      username: ["", [Validators.minLength(3)], [this.usernameValidator]],
      eMail: ["", [Validators.required, Validators.email], [this.emailValidator]],
    });

    this.pwData = this.fb.group({
      oldPassword: ["", [Validators.required, Validators.minLength(6)]],
      password: ["", [Validators.required, Validators.minLength(6)]]
    });
  }

  editSettings() {

 }

}
