<table class="ui celled structured table main" [ngClass]="{'hideStats': hideStat}">
  <thead>
    <tr>
      <th rowspan="2">Level</th>
      <th colspan="2" rowspan="2">Spiel Typ</th>
      <th colspan="5">
         <i *ngIf="!hideStat" class="compress icon" (click)="hideStat = true"></i>
         <i *ngIf="hideStat" class="expand icon" (click)="hideStat = false"></i>
         <label *ngIf="!hideStat">
         Statistik</label></th>
      <th rowspan="2">
         Bearbeiten
        <!--<i *ngIf="!hideDesc" class="compress icon" (click)="hideDesc = true"></i>
        <i *ngIf="hideDesc" class="expand icon" (click)="hideDesc = false"></i>
        <label *ngIf="!hideDesc">Beschreibung</label>-->
      </th>
    </tr>
   <tr>
      <th>Gespielt</th>
      <th>Gewonnen</th>
      <th>AVR</th>
      <th>AVR Gewonnen</th>
      <th>AVR Verloren</th>
   </tr>
  </thead>
  <tbody>

    <tr *ngFor="let level of levels; index as i" (dblclick)="loadInEdit(level)" [ngClass]="{hide: level.hide == true}">
      <td>


        <label *ngIf="level.ID">
           <label *ngFor="let obj of level.indent">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <a [href]="'/game/'+level.ID">
            <h4>Level {{level.ID}}</h4>
          </a>
        </label>
        <label *ngIf="level.ID == undefined">
           <label *ngFor="let obj of level.indent">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          &nbsp;
         <i *ngIf="levels[i+1].hide != true" class="small minus icon" (click)="minimize(i, level.to)"></i>
         <i *ngIf="levels[i+1].hide == true" class="small plus icon" (click)="maximise(i, level.to)"></i>
         <label class="title">{{level.name}}</label>
         </label>
      </td>
      <td>
         <div *ngIf="level.ID">
         <label class="bold">{{gameMode(level)}}</label>
         </div>
      </td>
      <td >
         <div *ngIf="level.ID">
            {{fingerMode(level)}}
         </div>
      </td>

      <td >
         <label *ngIf="level.ID && !hideStat">
         {{level.stat.numPlayed}} mal
         </label>
      </td>
      <td>
         <label *ngIf="level.ID && !hideStat">{{level.stat.won}} mal</label></td>
      <td>
         <label *ngIf="level.ID && !hideStat">{{level.stat.avrTime}}</label></td>
      <td>
         <label *ngIf="level.ID && !hideStat">{{level.stat.avrTimeWon}}</label></td>
      <td>
         <label *ngIf="level.ID && !hideStat">{{level.stat.avrTimeLost}}</label></td>
         <td>
            <div *ngIf="level.ID">
               <i (click)="editLevel(i, level.ID)" [routerLink]="['..', 'configure', level.ID]" class=" edit icon"></i>
               <i (click)="deleteLevel(i, level.ID)" class=" delete icon"></i>
              <i (click)="moveLevelUp(i, level.ID)" *ngIf="i>0" class=" angle up icon"></i>
              <i (click)="moveLevelDown(i, level.ID)" *ngIf="i<levels.length-1" class="angle down icon"></i>
           </div>
           <div *ngIf="level.ID == undefined">
              <!--<i (click)="editDividor(i)" [routerLink]="['..', 'configure', level.ID]" class=" edit icon"></i>-->
              <i (click)="deleteDivider(i)" class=" delete icon"></i>
              </div>


         </td>
      <!--<td>
        <div *ngIf="level.isLevel">
          {{level.description}}
        </div>
      </td>-->
    </tr>

  </tbody>
</table>

<br><br>
