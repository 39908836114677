import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-divide',
  templateUrl: './admin-divide.component.html',
  styleUrls: ['./admin-divide.component.scss']
})
export class AdminDivideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
