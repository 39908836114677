<!--<div class="flexbox">

<div class="ui small inverted menu">
  <a routerLink="configure" routerLinkActive="active" class="item">Levels Konfigurieren</a>
    <a routerLink="divide" routerLinkActive="active" class="item">Levels Untergliedern</a>
    <div class="right menu">
      <a routerLink="login" routerLinkActive="active" class="item">Login</a>

    </div>
</div>
<br>
<br>

<router-outlet class="body"></router-outlet>
</div>
-->

<div>
   <app-menu *ngIf="isLoggedIn" class="menu" [@menu]="menuActive ? 'open' : 'closed'"></app-menu>
   <div class="container" [@content]="menuActive ? 'right' : 'start'" (click)="disableMenu()">
     <div class="section">
        <router-outlet></router-outlet>
      </div>
   </div>
</div>
