
         <app-screen-header [status]="status"></app-screen-header>
         <br>
         <br>
         <button [ngClass]="{disabled: status == Medal.LOCKED}" class="startGame ui black button">Spiel Starten</button>
         <br>
         <br>
         <br>


         <app-statistic [stats]="levelUp" [name]="'Gewonnen'"  showPlayTime="false"></app-statistic>
      <br>

         <table>
            <tr>
               <td><i class="icon-medaille gold"></i></td><td>{{gold}}</td>

               <td><i class="icon-medaille silver"></i></td><td>{{silver}}</td>

               <td><i class="icon-medaille bronze"></i></td><td>{{bronze}}</td>
            </tr>
            </table>
            <br>
         <app-statistic [stats]="loose"  *ngIf="showLoose" [name]="'Verloren'" showPlayTime="false"></app-statistic>
