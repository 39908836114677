<table>
  <thead>
  </thead>
  <tbody>

    <tr *ngFor="let level of levels; index as i" (dblclick)="loadInEdit(level)" [ngClass]="{hide: level.hide == true, markLevel: level.ID == levelID}">
      <td>
        <label *ngIf="level.ID != undefined">
           <label *ngFor="let obj of level.indent">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <a (click)="closeMenu(level.ID)">
            <h4>Level {{level.ID}}</h4>
          </a>
        </label>
        <label *ngIf="level.ID == undefined">
           <label *ngFor="let obj of level.indent">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          &nbsp;
         <i *ngIf="levels[i+1].hide != true" class="small minus icon" (click)="minimize(i, level.to)"></i>
         <i *ngIf="levels[i+1].hide == true" class="small plus icon" (click)="maximise(i, level.to)"></i>
         <label class="title">{{level.name}}</label>
         </label>
      </td>
      <td>
         <i *ngIf="level.status == Medal.LOCKED" class="large lock icon"></i>
         <i *ngIf="level.status == Medal.UNLOCKED" class="large lock open icon"></i>

         <!--<i *ngIf="statusToInt(level.status) == statusToInt('LEVELUP')" class="large check icon"></i>-->

         <i *ngIf="level.status >= Medal.BRONZE" class="large icon-medaille bronze"></i>
         <i *ngIf="level.status >= Medal.SILVER" class="large icon-medaille silver"></i>
         <i *ngIf="level.status == Medal.GOLD" class="large icon-medaille gold"></i>


         <i *ngIf="level.status < Medal.BRONZE && level.status >= Medal.LEVELUP" class="large inactive icon-medaille bronze"></i>
         <i *ngIf="level.status < Medal.SILVER && level.status >= Medal.LEVELUP" class="large inactive icon-medaille silver"></i>
         <i *ngIf="level.status != Medal.GOLD && level.status >= Medal.LEVELUP" class="large inactive icon-medaille gold"></i>



      </td>
    </tr>

  </tbody>
</table>
