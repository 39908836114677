import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";

import { Level } from "../../shared/settings";
import { LevelsService } from "../../shared/levels.service";
import { NgEventBus } from 'ng-event-bus';
import { MetaData } from "ng-event-bus/lib/meta-data";
import { LevelToText} from "../../shared/level-to-text";
import { ActivatedRoute } from "@angular/router";
import { SettingsService } from "../../shared/settings.service";

@Component({
  selector: 'app-divide-table',
  templateUrl: './divide-table.component.html',
  styleUrls: ['./divide-table.component.scss']
})
export class DivideTableComponent implements OnInit {


     levels: any[];
     //tableSettings = [];
     expanded = false;
     hideStat = true;
     hideDesc = false;


     constructor(
       private ls: LevelsService,
       private eventBus: NgEventBus,
       private route: ActivatedRoute,
       private settings: SettingsService,
     ) { }

     ngOnInit(): void {
        this.eventBus.on("refresh").subscribe((meta: MetaData) => {
           this.refreshLevels();
        });

       //this.ls.getAllLevels().subscribe(data => {
         this.refreshLevels();
       //});

       this.maximiseToLevel();
     }

     fingerMode(level) {
        return this.settings.getFingerModeStr();
     }

     gameMode(level) {
        return this.settings.getLageStr();
     }


     private moveLevel(index, ID, num) {
        ID = ID-0;
       if (this.levels[index +num].ID == ID + num) { // swap
         console.log("SWAP");
         this.ls.swapLevel(this.levels[index +num],this.levels[index]).subscribe(success => {
           if (success) {
              this.refreshLevels();
           } else {
             alert("Es ist ein Fehler aufgetreten");
           }
         });
       } else { // Only Update
         const level = { ...this.levels[index], ID: ID + num };
         this.ls.updateLevel(ID, level).subscribe(success => {
           if (success) {
              this.refreshLevels();
           } else {
             alert("Es ist ein Fehler aufgetreten");
           }
         });
       }
     }

     moveLevelUp(index, ID) {
       this.moveLevel(index, ID, -1);
     }

     moveLevelDown(index, ID) {
       this.moveLevel(index, ID, 1);
     }

     deleteLevel(index, ID) {
       if (confirm("Wollen Sie das Level " + ID + " wirklich löschen?")) {
         this.ls.deleteLevel(ID).subscribe(success => {
           if (success) {
             this.levels.splice(index, 1);
             alert("Level erfolgreich gelöscht");
             this.refreshLevels();
           } else {
             alert("Es ist ein Fehler aufgetreten");
           }
         });
       }
     }

     editLevel(index, ID) {
        const level = this.levels[index];
        console.log("editLevel", level);
   this.eventBus.cast("editLevel", level);
    }

     loadInEdit(level) {
       console.log("dblClick", level);
   this.eventBus.cast("dblClick", level);
   }

   deleteDivider(index) {
      const divider = this.levels[index];
      this.ls.deleteDivider(divider).subscribe(success => {
         if(success) {
            alert("Einrückung erfolgreich gelöscht");
            this.refreshLevels();
         }
      });
   }

   private getNextLevelIndex(index) {
      for(let i=index;i<this.levels.length;i++) {
         if(this.levels[i].ID != undefined) {
            return i;
         }
      }
      return undefined;
   }

   private checkMinMaxCondition(index, maxLevel) {
      return index < this.levels.length && (this.levels[index].ID <= maxLevel || this.levels[index].ID == undefined);
   }

   minimize(index, maxLevel) {
      console.log("MINIMIZE", index, maxLevel);
      if(this.levels[index].ID == undefined) {
         this.levels[index].isHidden = true;
         index++;
      } else {
         this.levels[index-1].isHidden = true;
      }

      // when ID < maxLevel or level.ID == undefined;

      for(;this.checkMinMaxCondition(index, maxLevel);index++) {
         if(this.levels[index].ID == undefined) {

            if(!this.checkMinMaxCondition(this.getNextLevelIndex(index), maxLevel))
               break;
         }
         this.levels[index].hide = true;
      }
/*
      for(index++;index < this.levels.length && (this.levels[index].ID != undefined && this.levels[index].ID<=maxLevel || this.levels[index].ID == undefined);index++) {
         this.levels[index].hide = true;
      }
      if(this.levels[index-1].ID == undefined) {
         this.levels[index-1].hide = false;
      }
*/
   }

   private getIndexToLevel(levelID) {
      for(let i=0;i<this.levels.length;i++) {
         if(this.levels[i].ID == levelID)
            return i;
      }
   }

   maximise(index, maxLevel) {
      console.log("MAXIMISE", index, maxLevel);

      if(this.levels[index].ID == undefined) {
         this.levels[index].isHidden = false;
         index++;
      } else {
         this.levels[index-1].isHidden = false;
      }


      for(;this.checkMinMaxCondition(index, maxLevel);index++) {
         this.levels[index].hide = false;
         if(this.levels[index].ID == undefined && this.levels[index].isHidden == true) {
            index = this.getIndexToLevel(this.levels[index].to);
         }
      }

/*
      for(index++;index < this.levels.length && (this.levels[index].ID != undefined && this.levels[index].ID<=maxLevel || this.levels[index].ID == undefined);index++) {
         console.log(index);
         if(this.levels[index].ID == undefined && this.levels[index].isHidden) {
            this.levels[index].hide = false;
            const arr = this.levels.filter(level => level.ID == this.levels[index].to);
            //console.log(arr.length);
            console.log("set INdex", this.levels.indexOf(arr[0]));
            index = this.levels.indexOf(arr[0].to);
            if(index == -1) {
               console.log("ERROR");
               break;
            }
         } else {
            this.levels[index].hide = false;
         }
      }*/
   }




   maximiseToLevel() {

      let ID: any = this.route.snapshot.paramMap.get("level");
      ID = ID -0;
      if(ID == undefined || ID == 0 || this.levels == undefined)
         return false;

      for(let i=0;i<this.levels.length;i++) {
         if(this.levels[i].ID == undefined) {
            if(this.levels[i].from <=ID && this.levels[i].to >= ID)
               this.maximise(i, this.levels[i].to);
         }
      }
   }

   private refreshLevels() {

      let getIndent = function (index, levels) {
         let indentNum = 0;
         for(let i = index; i<levels.length;i++) {
            if(levels[i].ID != undefined) {
               let tmp = [...levels[i].indent];
               while(indentNum<0) {
                  tmp.pop();
                  indentNum++;
               }
               return tmp;
            }
            indentNum--;
         }
         return undefined;
      }

      this.ls.getLevelSystem().subscribe(data => {
         let newLevels = data.levels;
         newLevels.map(level => level.ID -=0);

         for(let i=0;i<data.dividers.length;i++) {
            let indentElements = newLevels.filter(level => level.ID <= data.dividers[i].to && level.ID >= data.dividers[i].from);

            //console.log(data.dividers[i].from, data.dividers[i].to, indentElements.length);

            indentElements.map(level => {
               if(level.indent == undefined) {
                  level.indent = [1];
               } else {
                  level.indent.push(1);
               }
               return level;
            });
            delete data.dividers[i].ID;
            let index = newLevels.indexOf(indentElements[0]);
            newLevels.splice(index, 0, data.dividers[i]);
         }
         let minimize = [];
         // correct indent for divisors
         for(let i=newLevels.length-1;i>=0;i--) {
            if(newLevels[i].ID == undefined) {
               newLevels[i].indent = getIndent(i, newLevels);
               if(!(this.levels != undefined && this.levels[i] != undefined && this.levels[i].ID == undefined && this.levels[i].isHidden != true)) {
                  minimize.push({index: i, to: newLevels[i].to})
               }
            }
         }
         this.levels = newLevels;
         for(let i=0;i<minimize.length;i++) {
            this.minimize(minimize[i].index, minimize[i].to);
         }

         this.maximiseToLevel();
         //console.log("ALL DATA", data);
         //console.log(this.levels);
      });
   }

}
