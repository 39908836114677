<div class="ui middle aligned center aligned grid">
  <div class="column">
    <h2 class="ui blue image header">
      <img src="assets/Logo.png" class="image" alt="LOGO"><br><br>
      <div class="content">
        Kennwort Vergessen?
      </div>
    </h2>
    <form class="ui large form" (ngSubmit)="submit()" [formGroup]="lostPw">
      <div class="ui stacked segment">
         <label>Geben Sie ihre E-Mail Adresse unten ein und wir helfen Ihnen, ein neues Passwort festzulegen</label><br><br>
        <div class="field">
   <label for="username">E-Mail Adresse</label>
          <div class="ui left icon input">
            <i class="user icon"></i>
            <input type="text" formControlName="eMail" placeholder="E-mail Addresse">
          </div>
        </div>
        <button type="submit" [disabled]="lostPw.invalid"  class="ui fluid large blue submit button">Weiter</button>
      </div>
    </form>
    <div class="ui message">
      <a routerLink="/login">Zurück zum Login</a>
  </div>
  <div class="ui message">
      Neu bei uns? <a routerLink="/sign-in">Hier Registrieren</a>
  </div>
  </div>
</div>
<div *ngIf="showMessage" class="ui active dimmer">
   <div class="ui large text">
      Wenn Ihre E-Mail Adresse  in unserem System exisitiert senden wir Ihnen einen Link zum Ändern Ihres Passwortes.<br><br>
      Es kann einige Minuten dauern bis die E-Mail bei ihnen ankommt. Bitte checken sie gegebenfalls ihren Spam Ordner.<br><br>

      <a routerLink="/login">Zurück zum Login</a>
      <br>
      <br>

   </div>
   <!--
   <div class="ui large text loader">Daten werden geladen ...</div>-->
</div>
