import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { AuthService } from "../../shared/auth.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-new-pw',
  templateUrl: './new-pw.component.html',
  styleUrls: ['./new-pw.component.scss']
})
export class NewPwComponent implements OnInit {

   newPw: FormGroup;
   showLoading = false;
   showSuccess = false;
   showInValid = false;
   showError = false;

   private ID: String;
   private passCode: String;

  constructor(
     private fb: FormBuilder,
     private auth: AuthService,
     private route: ActivatedRoute,
     private router: Router
 ) { }

 ngOnInit(): void {
    this.ID = this.route.snapshot.paramMap.get("ID");
    if(this.ID == null) {
      alert("yes");
      this.router.navigateByUrl("/login");
   }

    this.initForm();

    this.passCode = this.route.snapshot.paramMap.get("passCode");
    console.log(this.passCode);


    //this.loading = false;
 }

 private initForm() {
   this.newPw = this.fb.group({
     password: ["", [Validators.required, Validators.minLength(6)]],
   });
 }

 submit() {
    this.showLoading = true;
 this.auth.newPw(this.ID, this.passCode, this.newPw.get("password").value).subscribe((data) => {
    this.showLoading = false;
    if(data == "invalid") {
      this.showInValid = true;
   } else if(data == true) {
      this.showSuccess = true;
   } else {
      this.showError = true;
   }
}, (error) => {
   this.showLoading = false;
   this.showError = true;
})
}

}
