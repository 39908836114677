import { Component, OnInit, Input } from '@angular/core';
import { Medal } from "../../../shared/medal";

@Component({
  selector: 'app-break-screen',
  templateUrl: './break-screen.component.html',
  styleUrls: ['./break-screen.component.scss']
})
export class BreakScreenComponent implements OnInit {

   @Input() levelID?:number = null;
   @Input() status?:number = Medal.LOCKED;

  constructor(
 ) { }

  ngOnInit(): void {

  }

}
