import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

import { Level, GROUP_MODE, COLOR_MARKUP_FINGERBOARD, FINGER_MODE, OUTPUT_MODE, GAME_MODE, NOTE_SHOW_FINGER_MODE, TONALITIES, LevelCondition, LEVEL_CONDITION_TYPE, settingsFormToSettings, settingsToFormSettings } from "../../shared/settings";


import { LevelsService } from "../../shared/levels.service";

import { NgEventBus } from 'ng-event-bus';
import { MetaData } from "ng-event-bus/lib/meta-data";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-form-configure',
  templateUrl: './form-configure.component.html',
  styleUrls: ['./form-configure.component.scss']
})
export class FormConfigureComponent implements OnInit {

   showForm = false;
  // active menu index
  active = 5;
  edit = false;
  IDexists = false;

  level: Level;
  //IDs: Number[];
  formSettings: FormGroup;
  //formLevelCondition: FormGroup;

  levelCondition: LevelCondition[] = [];
  //bronzeCondition: LevelCondition[] = [];
  //silverCondition: LevelCondition[] = [];
  //goldCondition: LevelCondition[] = [];

  constructor(
    private fb: FormBuilder,
    private ls: LevelsService,
    private eventBus: NgEventBus,
    private route: ActivatedRoute
  ) { }

  newLevelCondition (cond: LevelCondition[]) {
     console.log("level", cond);
    this.levelCondition = cond;
 }
/*
 newBronzeCondition (cond: LevelCondition[]) {
   this.bronzeCondition = cond;
}
newSilverCondition (cond: LevelCondition[]) {
  this.silverCondition = cond;
}

newGoldCondition (cond: LevelCondition[]) {
  this.goldCondition = cond;
}*/

  ngOnInit(): void {
    this.initForm();

    /*this.ls.getAllLevels().subscribe(levels => {
      levels = levels.getAllLevels;
      this.IDs = [];
      for(let i=0;i<levels.length;i++) {
         this.IDs.push(levels[i].ID);

      }
      this.formSettings.patchValue({insertAfter: levels[levels.length-1].ID});
   })*/

    if(this.route.snapshot.paramMap.get("editLevel") != undefined) {

      this.ls.getLevel(parseInt(this.route.snapshot.paramMap.get("editLevel"))).subscribe(data => {
         console.log("LOAD EDIT", data);
         this.initEdit(data);
      });
   }

    this.eventBus.on("dblClick").subscribe((meta: MetaData) => {
      this.edit = false;
      console.log("dlcClick Reseved", meta);
      const level = meta.data;
      const settings = settingsToFormSettings(level);

      this.formSettings.patchValue(settings);
      this.levelCondition = settings.levelCondition;
      /*
      this.bronzeCondition = settings.bronzeCondition;
      this.silverCondition = settings.silverCondition;
      this.goldCondition = settings.goldCondition;
*/

      //this.formLevelCondition.patchValue(settings);

      alert("Einstellungen von Level "+level.ID+" übernommen");
    });

    this.eventBus.on("editLevel").subscribe((meta: MetaData) => {
      this.initEdit(meta.data);
    });


  }

  initEdit(data) {
     console.log("edit", data);
     this.showForm = true;
     this.edit = true;

     this.level = data;
     const settings = settingsToFormSettings(this.level);
     this.formSettings.patchValue(settings);
     this.levelCondition = settings.levelCondition;
     /*
     this.bronzeCondition = settings.bronzeCondition;
     this.silverCondition = settings.silverCondition;
     this.goldCondition = settings.goldCondition;*/
     //this.formLevelCondition.patchValue(settings);
 }



  initForm() {
    if (this.formSettings) { return; }

/*
    this.formLevelCondition = this.fb.group({
      type: 0,
      number: "",
      win: true
   });*/

    this.formSettings = this.fb.group({
      ID: [{ value: '', disabled: this.edit }, [Validators.required]],
      insertAfter: "",
      description: "",


      //Noten im Spiel
      lagen: this.fb.group({
        lage1: true,
        lage2: false,
        lage3: false,
        lage4: false,
        lage5: false,
        lage6: false,
      }),
      group: GROUP_MODE.group1,
      tonality: TONALITIES.whole,
      basicTone: true,

      //Geige
      rotation: 0,
      showHelpLines: true,
      showFinger: true,
      showCssDur: true,
      colorMarkupPoints: COLOR_MARKUP_FINGERBOARD.cDur,

      numNotesFingerBoard: 5,
      playingLageAuto: true,
      fingerMode: FINGER_MODE.click,

      //Notenzeile
      outputMode: OUTPUT_MODE.picAndLetter,
      numNotesNoteBoard: 5,
      showFingerNum: NOTE_SHOW_FINGER_MODE.none,

      //Spieltyp
      notesTurnable: true,
      defaultShowNames: true,
      tippsActive: true,
      showTippAfterNumWrongOnes: 2,
      gameMode: GAME_MODE.findNote,


      //Konditionen
      //endCondition: Object[]
    });
  }



  submit() {
    let settings = settingsFormToSettings(this.formSettings.value);
    settings.levelCondition = this.levelCondition;
    /*settings.bronzeCondition = this.bronzeCondition;
    settings.silverCondition = this.silverCondition;
    settings.goldCondition = this.goldCondition;*/

    console.log(settings);

    if (this.edit == false) {
      this.ls.newLevel(settings).subscribe(success => {
        if (success.newLevel) {
          alert("Datensatz erfolgreich eingefügt");
          this.eventBus.cast("refresh", true);
       } else {
          alert("Es ist ein Fehler aufgetreten");
        }
      },
        error => alert("Es ist ein Fehler aufgetreten"));
    } else {
      this.ls.updateLevel(this.level.ID, settings).subscribe(success => {
         console.log("return update Level", success);
        if (success) {
          alert("Datensatz erfolgreich bearbeitet");
          this.eventBus.cast("refresh", true);
        this.edit = false;
     } else {
          alert("Es ist ein Fehler aufgetreten");
        }
      },
        error => alert("Es ist ein Fehler aufgetreten"));
    }
  }

}
