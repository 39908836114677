export class Medal {

   public static toInt(medal: string) {
      if(medal == "UNLOCKED") {
         return Medal.UNLOCKED;
      } else if(medal == "LEVELUP") {
         return Medal.LEVELUP;
      } else if(medal == "BRONZE") {
         return Medal.BRONZE;
      } else if(medal == "SILVER") {
         return Medal.SILVER;
      } else if(medal == "GOLD") {
         return Medal.GOLD;
      }
      return Medal.LOCKED; // LOCKED
   }

   public static unlockNext(medal) {
      if(typeof medal == "string") {
         medal = Medal.toInt(medal);
      }
      if(medal >= Medal.LOCKED)
         return true;
      return false;
   }
   public static unlockTwo(medal) {
      if(typeof medal == "string") {
         medal = Medal.toInt(medal);
      }
      if(medal >= Medal.GOLD)
         return true;
      return false;
   }

   public static LOCKED = 0;
   public static UNLOCKED = 1;
   public static LEVELUP = 2;
   public static BRONZE = 3;
   public static SILVER = 4;
   public static GOLD = 5;
}
