import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dimmed-message',
  templateUrl: './dimmed-message.component.html',
  styleUrls: ['./dimmed-message.component.scss']
})
export class DimmedMessageComponent implements OnInit, OnChanges {


      @Input() info;
      @Input() time;
      @Input() show = false;
      internShow = false;
      @Output() finished = new EventEmitter<Boolean>();

      timeout;

     constructor() { }

     ngOnInit(): void {
     }

     ngOnChanges() {
        if(this.show == true) {
           this.internShow = true;
           if(this.time) {
              this.timeout = setTimeout(function (context = this) {return () => {context.show = false; context.internShow = false; context.finished.emit(true);}}(this), this.time);
           }
        } else
         this.internShow = false;
    }
    close() {
      clearTimeout(this.timeout);
      this.internShow = false;
      this.show = false;
      this.finished.emit(true);
   }
}
