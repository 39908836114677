import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GameService } from "../service/game.service";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-finger-board',
  templateUrl: './finger-board.component.html',
  styleUrls: ['./finger-board.component.scss']
})
export class FingerBoardComponent implements OnInit {

  minMatrix: Boolean = true;
  // matrix is a [17][4] array
  matrix = [];
  multipleChoiseActive: Boolean = false;

  private initObj = {
    circle: true, // when false -> square
    playable: false,
    color: false,
    tipp: false,
    multipleChoise: false,
    showName: false
  };

  COLOR = {
    color0: 0,
    color1: 1,
    color2: 2,
    color3: 3,
    helpLines: 4,
    none: 5,
  };

  drop(event: CdkDragDrop<string[]>, row, column) {
    let obj = {
      finger: event.previousIndex,
      column: column,
      row: row,
      showTipp: this.matrix[row][column].tipp,
      showName: this.matrix[row][column].showName != false
    }

    this.game.game._inputListener.angularFingerDrop(obj);
    console.log("FINGERBOARD::DROP", obj);
  }

  click(row, column) {
     this.game.game._inputListener.angularFingerBoardButtonClick(row, column);
 }

  constructor(
    private game: GameService,
    private cdr: ChangeDetectorRef
  ) {
    game.game._view.subscribe(this.observe, this);
  }

  ngOnInit(): void {
    console.log("finger-board::ngOnInit");
    this.reset();
  }

  reset() {
    this.matrix = [];
    for (let i = 0; i < 17; i++) {
      this.matrix.push([]);
      for (let j = 0; j < 4; j++) {
        this.matrix[i].push({ ...this.initObj });
      }
    }
    console.log("finger-board::reset", this.matrix);
  }

  softReset() {
    this.matrix = this.matrix.map(arr => arr.map(element => {
      element.multipleChoise = false;
      element.tipp = false;
      element.showName = false;
      return element;
    }));
    this.refresh();
  }

  refresh() {

    this.refreshMinMatrix();
    this.refreshMultipleChoise();
  }

  refreshMinMatrix() {
    this.minMatrix = this.matrix.filter(arr => arr.filter(element => element.showName != false).length > 0).length <= 0;
    this.game.game._view.observe(!this.minMatrix, "PRINT_GAMEBOARD_currentLageBorderSIZE")
  }

  refreshMultipleChoise() {
    this.multipleChoiseActive = this.matrix.filter(arr => arr.filter(element => element.multipleChoise).length > 0).length > 0;
  }

  playableNotes(notes) {
    this.reset();
    console.log("FINGERBOARD::playableNotes", notes);
    for (let i = 0; i < notes.length; i++) {
      const x = notes[i].row, y = notes[i].column;
      this.matrix[x][y].playable = true;
      this.showName(notes[i]);
    }
    console.log("FINGERBOARD::playableNotes", this.matrix);
  }

  cssDur(matrix) {
    console.log("FINGERBOARD::cssDur", matrix, matrix.length, this.matrix.length);
    for (let i = 0; i < matrix.length; i++) {
      for (let j = 0; j < matrix[i].length; j++) {
        this.matrix[i][j].circle = matrix[i][j].includes(true) ? true : false;
      }
    }
    console.log("FINGERBOARD::cssDur", this.matrix);
  }

  colorMarkup(matrix) {
    for (let i = 0; i < matrix.length; i++) {
      for (let j = 0; j < matrix[i].length; j++) {
        this.matrix[i][j].color = matrix[i][j].color;
      }
    }
    console.log("FINGERBOARD::colorMarkup", this.matrix, matrix);
  }

  showTipp(note, isVisible) {
    if (note == true) {
      this.matrix = this.matrix.map(arr => arr.map(elem => { elem.tipp = false; return elem; }));
      return true;
    }
    this.matrix[note.row][note.column].tipp = isVisible;
  }

  removeMultipleChoise() {
    console.log(this.matrix);
    this.matrix = this.matrix.map(arr => arr.map(element => { element.multipleChoise = false; return element }));
    this.refreshMultipleChoise();
  }

  showMultipleChoise(notes) {
    this.removeMultipleChoise();

    for (let i = 0; i < notes.length; i++) {
      this.matrix[notes[i].row][notes[i].column].multipleChoise = true;
    }
    this.refreshMultipleChoise();
  }

  private nameToString(name) {
    if (Array.isArray(name)) {
      let str = "";
      for (let i = 0; i < name.length; i++) {
        if (i > 0)
          str += " | ";
        str += name[i];
      }
      return str;
    } else {
      return name;
    }
  }

  showName(note) {
    this.matrix[note.row][note.column].showName = (note.showName == false || note.showName == undefined) ? false : this.nameToString(note.name);
    this.refreshMinMatrix();
  }

  observe(arg, action) {

    const path = action.split("_");

    if (path[0] == "PRINT") {
      if (path[1] == "SETTINGS") {
        if (path[2] == "ID") {
          this.reset();
        }
      }
      if (path[1] == "GAMEBOARD") {
        switch (path[2]) {
          case "cssDur":
            this.cssDur(arg);
            break;
          case "colorMarkup":
            this.colorMarkup(arg);
            break;
          case "showTipp":
            switch (path[3]) {
              case "lage":
                //Tipp -> LAGE
                break;
              case "note":
                this.showTipp(arg, true);
                break;
              case "remove":
                this.showTipp(arg, false);
                break;
            }
            break;
          case "multiple":
            if (path[3] == "notes") {
              this.showMultipleChoise(arg);
            }
            break;
          case "showName":
            this.showName(arg);
            break;
          case "softReset":
            this.softReset();
            break;
        }
      }
    }

    switch (action) {
      case "printPlayableNotes":

        this.playableNotes(arg);
        break;

    }

    this.cdr.detectChanges();
  }

}
