<div class="ui middle aligned center aligned grid">
  <div class="column">
    <h2 class="ui blue image header">
      <img src="assets/Logo.png" class="image" alt="LOGO">
      <br><br>
      <div class="content">
        Neues Passwort
      </div>
    </h2>
    <form class="ui large form" (ngSubmit)="submit()" [formGroup]="newPw">
      <div class="ui stacked segment">
        <div class="field">
   <label for="username">Passwort</label>
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input type="text" formControlName="password" placeholder="Passwort">

        </div>
        <app-form-messages [control]="newPw.get('password')" controlName="password"></app-form-messages>
      </div>
        <button type="submit" class="ui fluid large blue submit button">Passwort Speichern</button>
      </div>
    </form>

    <div *ngIf="showSuccess" class="ui positive message">
     <div class="header">
       Ihr Passwort wurde erfolgreich gespeichert
     </div>
   </div>

   <div *ngIf="showError" class="ui negative message">
    <div class="header">
      Beim Sichern ihres Passworts ist ein Fehler aufgetreten.
    </div>
  </div>
  <div *ngIf="showInValid" class="ui negative message">
  <div class="header">
     Dieser Link ist nicht mehr gültig. Fordern sie erneut einen Link an um ihr Passwort zurückzusetzten.
  </div>
</div>
    <div *ngIf="showLoading" class="ui icon message">
      <i class="notched circle loading icon"></i>

      <div class=" left aligned content ">
        <div class=" left aligned header">
          Passwort Speichern
        </div>
        <p>Ihr neues Passwort wird gerade gesichert</p>
      </div>
    </div>
    <div class="ui message">
      <a routerLink="/login">Zurück zum Login</a>
  </div>

  </div>

</div>
