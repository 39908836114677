import { Component } from '@angular/core';
import { MenuService } from "./shared/menu.service";
import { AuthService } from "./shared/auth.service";
import { Title } from '@angular/platform-browser';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-root',
  animations: [
    trigger('menu', [
      // ...
      state('open', style({
        left: 0,
      })),
      state('closed', style({
        left: "-25em",
      })),
      transition('open => closed', [
        animate('0.3s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
    trigger('content', [
      // ...
      state('start', style({
        left: 0,
        right: 0
      })),
      state('right', style({
        left: "25em",
        right: "-25em"
      })),
      transition('start => right', [
        animate('0.3s')
      ]),
      transition('right => start', [
        animate('0.3s')
      ]),
    ]),
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'VioLearn';

  private counter = 0;

  menuActive = true;
  isLoggedIn = false;

  constructor(
     private menu: MenuService,
     private auth: AuthService,
     private titleService: Title
 ) {
   this.setTitle(this.title);
    menu.subscribe(menuActive => {
      this.counter = 0;
      this.menuActive = menuActive
   });
   this.auth.rights.subscribe((right)  => {this.isLoggedIn = (right != "NONE")? true : false; });
 }

 disableMenu() {
    if(this.menu.getValue() == true) {
      this.counter++;
      if(this.counter>1) {
         this.menu.next(false);
         this.counter = 0;
      }
   }
}

public setTitle(newTitle: string) {
  this.titleService.setTitle(newTitle);
}


}
