<h2>
   <i *ngIf="status == Medal.LOCKED" class="large lock icon"></i>
   <i *ngIf="status == Medal.UNLOCKED" class="large lock open icon"></i>
   <i *ngIf="status == Medal.LEVELUP" class="large check icon"></i>
   <i *ngIf="status == Medal.GOLD" class="large icon-medaille gold"></i>
   <i *ngIf="status == Medal.SILVER" class="large icon-medaille silver"></i>
   <i *ngIf="status == Medal.BRONZE" class="large icon-medaille bronze"></i>

   Level {{levelID}}
   <i *ngIf="status == Medal.LOCKED" class="large lock icon"></i>
   <i *ngIf="status == Medal.UNLOCKED" class="large lock open icon"></i>
   <i *ngIf="status == Medal.LEVELUP" class="large check icon"></i>
   <i *ngIf="status == Medal.GOLD" class="large icon-medaille gold"></i>
   <i *ngIf="status == Medal.SILVER" class="large icon-medaille silver"></i>
   <i *ngIf="status == Medal.BRONZE" class="large icon-medaille bronze"></i>
</h2>
<h3>{{lage}}{{group}}<br>{{fingerMode}}</h3>
