import { Component, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Statistic } from "../shared/statistic";
import { GameService } from "../service/game.service";
import { StatisticService } from "../service/statistic.service";
import { Observe } from "../shared/observe";
import { LevelCondition, LEVEL_CONDITION_TYPE } from "../shared/level-condition";

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent implements OnInit, OnChanges, Observe {

  @Input() stats?: Statistic;
  currStat: Statistic;
  printStat: Statistic;

  @Input() showPlayTime = true;
  playTimeStr = "00:00:00";

  @Input() name?: String = "Statistik";
  @Input() show?: any = { rightOnes: true, wrongOnes: true };
  private levelCondition: LevelCondition[];
  private isPlaying: Boolean = false;

  constructor(
    private game: GameService,
    private statistic: StatisticService,
    private cdr: ChangeDetectorRef) {

      this.statistic.playTimeStr.subscribe((val) => this.playTimeStr = val);
  }

  private refresh() {
     if (this.levelCondition == undefined) {
        this.levelCondition = this.game.settings.levelCondition;
     }
     if(this.currStat == undefined) {
        this.currStat = this.game.stats.getStat();
     }


    if (this.stats == undefined) {
      this.printStat = this.currStatToStatistic(this.currStat);
    } else {
      this.printStat = this.stats;
    }
    if (this.printStat == undefined) {
      this.printStat = {};
    }
  }

  ngOnInit(): void {
    console.log("STATISTIC::ngOnInit");
    this.game.game.subscribe(this.observe, this);
    this.game.stats.subscribe(this.observe, this);
    this.game.settings.subscribe(this.observe, this);

    this.refreshShow();
    this.refresh();
  }

  printPlayTime(time) {
    let addZero = function(num) {
      return num > 9 ? num : "0" + num;
    }
    return addZero(Math.floor(time / 360)) + ":" + addZero((Math.floor(time / 60)) % 60) + ":" + addZero(time % 60);
  }

  ngOnChanges() {
    this.refresh();
    this.refreshShow();
  }

  private refreshShow() {
    if (this.levelCondition == undefined)
      return false;


    if (this.show.rightOnes != true && this.levelCondition.filter(level => level.type == LEVEL_CONDITION_TYPE.rightOnes).length > 0) {
      this.show.rightOnes = true;
    }
    if (this.show.wrongOnes != true && this.levelCondition.filter(level => level.type == LEVEL_CONDITION_TYPE.wrongOnes).length > 0) {
      this.show.wrongOnes = true;
    }
    if (this.show.rightOnesInARow != true && this.levelCondition.filter(level => level.type == LEVEL_CONDITION_TYPE.rightOnesInARow).length > 0) {
      this.show.rightOnesInARow = true;
    }
    if (this.show.wrongOnesInARow != true && this.levelCondition.filter(level => level.type == LEVEL_CONDITION_TYPE.wrongOnesInARow).length > 0) {
      this.show.wrongOnesInARow = true;
    }
    if (this.show.duration != true && this.levelCondition.filter(level => level.type == LEVEL_CONDITION_TYPE.duration).length > 0) {
      this.show.duration = true;
    }
    if (this.show.timeUntilNextMove != true && this.levelCondition.filter(level => level.type == LEVEL_CONDITION_TYPE.timeUntilNextMove).length > 0) {
      this.show.timeUntilNextMove = true;
    }
    console.log("STATISTIC::refreshShow", this.show)
    this.refresh();
  }


  currStatToStatistic(stat): Statistic {
    console.log("statistic::currStatToStatistic");
    let newStat: Statistic = {};

    if (stat.rightOnes > 0 || this.show.rightOnes)
      newStat.rightOnes = stat.rightOnes;
    if (stat.wrongOnes > 0 || this.show.wrongOnes)
      newStat.wrongOnes = stat.wrongOnes;
    if (stat.rightOnesInARow > 0 || this.show.rightOnesInARow)
      newStat.rightOnesInARow = stat.rightOnesInARow;
    if (stat.wrongOnesInARow > 0 || this.show.wrongOnesInARow)
      newStat.wrongOnesInARow = stat.wrongOnesInARow;
    if (stat.duration > 0 || this.show.duration)
      newStat.duration = stat.duration;
    if (stat.timeUntilNextMove > 0 || this.show.timeUntilNextMove)
      newStat.timeUntilNextMove = stat.timeUntilNextMove;
    if (stat.playTime > 0)
      newStat.playTime = stat.playTime;

    return newStat;
  }



  observe(arg, action) {
    const path = action.split("_");
    /*
    if (path[0] == "GAME") {
      console.log("statistic::observe::GAME", arg);
      switch (path[1]) {
        case "start":
          this.start();
          break;
        case "stop":
          this.stop(arg);
          break;
        case "pause":
          this.pause(arg);
          break;
      }
    }
    */
    if (action == "STATISTIC_NEW") {
      console.log("statistic::observe new stat", arg);
      this.currStat = arg;
      this.refresh();
    }
    if (action == "SETTINGS_levelCondition") {
      this.levelCondition = arg;
      this.refreshShow();
    }
    this.cdr.detectChanges();
  }

}
