import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GameService } from "../service/game.service";
declare const VioLearn: any;

@Component({
  selector: 'app-drag-fingers',
  templateUrl: './drag-fingers.component.html',
  styleUrls: ['./drag-fingers.component.scss']
})
export class DragFingersComponent implements OnInit {

  droppableList;
  lage = 0;
  pos = [0, 2, 3, 5];

  constructor(
    private game: GameService,
    private cdr: ChangeDetectorRef
  ) {
    this.droppableList = [];
    for (let row = 0; row < VioLearn.matrix.length; row++) {
      for (let column = 0; column < VioLearn.matrix[row].length; column++) {
        this.droppableList.push("finger" + row + "_" + column);
      }
    }
    this.game.game._fingerBoard.subscribe(this.observe, this);

  }

  ngOnInit(): void {
  }


  setFingers(lage) {
     this.lage = lage;
    switch (lage) {
      case 3:
      case 0:
        this.pos = [0, 2, 3, 5];
        break;
      case 1:
      case 4:
        this.pos = [0, 1, 3, 5];
        break;
      case 2:
      case 5:
        this.pos = [0, 2, 4, 5];
        break;
    }

  }

  observe(arg, action) {
    const path = action.split("_");
    if (path[0] == "FINGERBOARD") {
      if (path[1] == "playingLage") {
        this.setFingers(arg);
      }
    }
    this.cdr.detectChanges();
  }

}
