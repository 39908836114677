import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BehaviorSubject<boolean>{

  constructor() {
     super(false);
  }
}
