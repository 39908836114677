<div class="fingers"
cdkDropList
   #dragFingers="cdkDropList"
   [cdkDropListConnectedTo]="droppableList">
   <div *ngFor="let fingerPos of pos;index as i" class="finger" [ngClass]="['pos'+lage+'_'+fingerPos, 'f_'+i]" cdkDrag><img src="assets/fingerprint.png"></div>
   <!--<div class="finger pos0" cdkDrag><img src="assets/fingerprint.png"></div>
   <div class="finger pos2" cdkDrag><img src="assets/fingerprint.png"></div>
   <div class="finger pos3" cdkDrag><img src="assets/fingerprint.png"></div>
   <div class="finger pos5" cdkDrag><img src="assets/fingerprint.png"></div>-->
</div>
