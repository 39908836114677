import { Component, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { GameService } from "../../service/game.service";
import { LevelToText } from "../../../shared/level-to-text";
import { SettingsService } from "../../../shared/settings.service";
import { Medal } from "../../../shared/medal";
declare const GAME_MODE: any;

@Component({
  selector: 'app-screen-header',
  templateUrl: './screen-header.component.html',
  styleUrls: ['./screen-header.component.scss']
})
export class ScreenHeaderComponent implements OnInit {

Medal = Medal;
  fingerMode;
  lage;
  group;
  levelID: number;
  @Input() status?:number = Medal.LOCKED;



  constructor(
    private settings: SettingsService,
  ) { }

  ngOnInit(): void {
    this.settings.fingerModeStr.subscribe(str => this.fingerMode = str);
    this.settings.lageStr.subscribe(str => this.lage = str);
    this.settings.groupStr.subscribe(str => this.group = (str != "") ? " / "+str : "");
    this.settings.ID.subscribe(ID => this.levelID = ID);
  }

}
