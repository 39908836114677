import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { User } from "./user";
import { ServiceTemplate } from "./service-template";

@Injectable({
  providedIn: 'root'
})



export class AuthService extends ServiceTemplate {

   protected path = "/PHP/Login/index.php";

  private rights$ = new BehaviorSubject<string>("NONE");
  public rights = this.rights$.asObservable();

  constructor(
    http: HttpClient
  ) {
     super(http);
  }

public getAllData() {
     return this.post({getAllData: true}).pipe(map(data => data.getAllData));
 }
 public editUsernameAndEmail(data) {
    return this.post({editUsernameAndEmail: data}).pipe(map(data => data.editUsernameAndEmail));
}

public editName(data) {
   return this.post({editName: data}).pipe(map(data => data.editName));
}

public editPassword(data) {
   return this.post({editPassword: data}).pipe(map(data => data.editPassword));
}

  private getIntToRight(type) {
     switch(type) {
        case "ADMIN":
            return 100;
         case "TESTUSER":
            return 1;
         default:
            return 0;
     }
 }

  public access(type) {

    if (this.rights$.getValue() != 'NONE' && this.getIntToRight(type) <= this.getIntToRight(this.rights$.getValue())) {
      return of(true);
    }
    if (this.rights$.getValue()  == 'NONE') {
      return this.getRights().pipe(map(data => this.getIntToRight(data) >= this.getIntToRight(type)));
    }
    return of(false);
  }

  public getRights() {
    let obj = this.post({ rights: true }).pipe(
      map(data => data.rights)
    );
    obj.subscribe(data => {this.rights$.next(data); console.log("SET RIGHTS: ", data)});
    return obj;
  }

  public isAdmin() {
     return this.access("ADMIN");
     /*
    return this.post({ isAdmin: true }).pipe(
      map(data => data.isAdmin)
   );*/
  }

  public isTestUser() {
    return this.post({ rights: true }).pipe(
      map(data => data.rights == "TESTUSER")
    );
  }

  public newPw(ID: String, passCode: String, pw: String) {
    console.log("newPw", ID, passCode, pw);
    return this.post({ newPw: pw, ID, passCode }).pipe(
      map(data => data.newPw)
    );
  }

  public lostPw(eMail: String): Observable<any> {
    console.log("lostPw", eMail);
    return this.post({ lostPw: eMail });
  }

  public usernameExists(username: String): Observable<any> {
    console.log("checkUsernameExists", username);
    return this.post({ usernameExists: username }).pipe(
      map(data => data.usernameExists)
    );
  }

  public eMailExists(username: String): Observable<Boolean> {
    return this.post({ eMailExists: username }).pipe(
      map(data => data.eMailExists)
    );
  }

  public createUser(user: User): Observable<any> {
    return this.post({ createUser: user });
  }

  public login(username: String, password: String): Observable<any> {
    return this.post({ login: username, password });
  }

  public logout() {
     this.post({logout: true}).subscribe(() => {});
 }

}
