<div>
   <app-screen-header [status]="status"></app-screen-header>
   <br>
   <br>
<div class="gameStats">
   <app-win-statistic></app-win-statistic>
   <app-statistic></app-statistic>
</div>
<br>
<button class="startGame ui black button">Nochmal Spielen</button>
