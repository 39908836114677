import { Observe } from "./shared/observe";
import { Statistic } from "./shared/statistic";
import { GameService } from "./service/game.service";
import { LevelCondition, LEVEL_CONDITION_TYPE } from "../shared/settings";
import { OnInit } from '@angular/core';
declare var Publisher:any;

export class Conditions extends Publisher implements Observe {

   levelCondition: LevelCondition[];
   bronzeCondition: LevelCondition[];
   silverCondition: LevelCondition[];
   goldCondition: LevelCondition[];
   winCondition: LevelCondition[];    // win Gold Condition
   looseCondition: LevelCondition[];  // loose level Condition

   constructor(private game:GameService) {
      super();
      if(this.game.settings == undefined) {
         setTimeout(this.init, 1000);
      } else {
         this.init();
      }

   }

   init() {
      this.game.settings.subscribe(this.observe, this);
      this.game.stats.subscribe(this.observe, this);

      this.levelCondition = this.game.settings.levelCondition;
      this.bronzeCondition = this.game.settings.bronzeCondition;
      this.silverCondition = this.game.settings.silverCondition;
      this.goldCondition = this.game.settings.goldCondition;
      this.compileWinLooseCondition();
      this._fire({}, "CONDITIONS_NEW");
   }

   public static compileToStat(cond: LevelCondition[]): Statistic {
      if(cond == undefined)
       return {};

      let stat: Statistic = {};

      stat.duration = cond.filter(cond => cond.type == LEVEL_CONDITION_TYPE.duration)[0]?.number;


     stat.rightOnes = cond.filter(cond => cond.type == LEVEL_CONDITION_TYPE.rightOnes)[0]?.number;

     stat.wrongOnes = cond.filter(cond => cond.type == LEVEL_CONDITION_TYPE.wrongOnes)[0]?.number;

     stat.wrongOnesInARow = cond.filter(cond => cond.type == LEVEL_CONDITION_TYPE.wrongOnesInARow)[0]?.number;

     stat.rightOnesInARow = cond.filter(cond => cond.type == LEVEL_CONDITION_TYPE.rightOnesInARow)[0]?.number;

     stat.timeUntilNextMove = cond.filter(cond => cond.type == LEVEL_CONDITION_TYPE.timeUntilNextMove)[0]?.number;

     for(let key in stat) {
        if(stat[key] == undefined) {
           delete stat[key];
        }
     }
     return stat;
   }

   compileWinLooseCondition() {
      var filter = function (newCondition, win) {
         var winCond = newCondition[0].filter(cond => cond.win == win);
         if(winCond.length > 0) {
            return winCond;
         } else if(newCondition.length > 1){
            return filter(newCondition.slice(1), win);
         } else {
            return [];
         }
      }

      this.winCondition = filter([this.goldCondition, this.silverCondition, this.bronzeCondition, this.levelCondition], true);

      this.looseCondition = filter([this.levelCondition, this.bronzeCondition, this.silverCondition, this.goldCondition], false)
      //console.log("WIN", this._winCondition);
      //console.log("LOOSE", this._looseCondition);
   }

   public observe(arg, action) {
      const path = action.split("_")
      if(path[0] == "SETTINGS") {
         if(this[path[1]] != undefined) {
            this[path[1]] = arg;
            this.compileWinLooseCondition();
            this._fire({}, "CONDITIONS_NEW");
         }
      } else if(path[0] == "STATISTIC")
         if(path[1] == "NEW") {

         }
   }
}
